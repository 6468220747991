import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import firebase from 'firebase';
import createPersistedState from 'vuex-persistedstate';
import * as Cookies from 'js-cookie';
// import 'firebase/auth';

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState({
    getState: (key) => Cookies.getJSON(key),
    setState: (key, state) => Cookies.set(key, state, { expires: 3, secure: true }),
  })],
  state: {
    userData: {},
    guestData: {},
    jwt: '',
    loggedIn: false,
    selectedAss: null,
    guestRespId: null,
    respInfo: null,
    emailVerified: false,
    currentUser: null,
    error: false,
    loadScreen: false,
  },
  actions: {
    login(context, userInfo) {
      return new Promise((resolve, reject) => {
        firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
          .then(() => {
            firebase.auth().signInWithEmailAndPassword(userInfo.email, userInfo.password)
              .then(() => {
                context.commit('setCurrentUser', { currentUser: firebase.auth().currentUser });
                const { emailVerified } = firebase.auth().currentUser;
                context.commit('setEmailVerified', { emailVerified });
                firebase.auth().currentUser.getIdToken(false)
                  .then((idToken) => {
                    context.dispatch('getUserData', { idToken })
                      .then(() => {
                        firebase.auth().onAuthStateChanged((user) => {
                          if (user) {
                            context.commit('setLoggedIn', true);
                            axios.post('/api/lastLogin', { lastLogin: new Date(), idToken })
                              .then(() => {
                                resolve();
                              })
                              .catch(() => {
                                reject();
                              });
                          } else {
                            context.commit('setLoggedIn', false);
                          }
                        });
                        resolve();
                      });
                  });
              })
              .catch((error) => {
                reject(error);
              });
          });
      });
    },
    googleRegister(context, userData) {
      return new Promise((resolve, reject) => {
        axios.post('/api/googleRegister', userData)
          .then(() => {
            // console.log(userData);
            firebase.auth().currentUser.getIdToken(false)
              .then((idToken) => {
                context.dispatch('getUserData', { idToken })
                  .then(() => {
                    context.commit('setEmailVerified', { emailVerified: true });
                    resolve();
                  })
                  .catch(() => {
                    reject();
                  });
              })
              .catch(() => {
                reject();
              });
          });
      });
    },
    googleLogin(context) {
      return new Promise((resolve, reject) => {
        firebase.auth().currentUser.getIdToken(false)
          .then((idToken) => {
            context.dispatch('getUserData', { idToken })
              .then(() => {
                firebase.auth().onAuthStateChanged((user) => {
                  if (user) {
                    context.commit('setEmailVerified', { emailVerified: true });
                    context.commit('setLoggedIn', true);
                    axios.post('/api/lastLogin', { lastLogin: new Date(), idToken })
                      .then(() => {
                        resolve();
                      })
                      .catch(() => {
                        reject();
                      });
                  } else {
                    context.commit('setLoggedIn', false);
                  }
                });
                resolve();
              });
          })
          .catch(() => {
            reject();
          });
      });
    },
    logout(context) {
      return new Promise((resolve, reject) => {
        firebase.auth().signOut()
          .then(() => {
            context.commit('clearUserData');
            resolve();
          })
          .catch((error) => {
            alert(error.message);
            reject();
          });
      });
    },
    getUserData(context, idToken) {
      return new Promise((resolve, reject) => {
        axios.post('/api/getUserInfo', idToken)
          .then((result) => {
            context.commit('setUserData', { result });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    userRegister(context, userData) {
      return new Promise((resolve, reject) => {
        firebase
          .auth()
          .createUserWithEmailAndPassword(userData.email, userData.password)
          .then(() => {
            const payload = {
              name: userData.name,
              email: userData.email,
              tier: userData.tier,
              signUpDate: userData.signUpDate,
              lastLogin: userData.lastLogin,
            };
            // console.log(payload);
            const user = firebase.auth().currentUser;
            firebase.auth().currentUser.getIdToken(false)
              .then((idToken) => {
                user.sendEmailVerification()
                  .then(() => {
                    // console.log('Verification email sent');
                    resolve();
                  })
                  .catch((error) => {
                    reject(error);
                  });
                axios.post('/api/register', { payload, idToken })
                  .then((res) => {
                    if (res.status === 200) {
                      context.dispatch('getUserData', { idToken })
                        .then(() => {
                          resolve();
                        });
                    }
                  });
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    uploadResponseImage(context, payload) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const fileName = `rsp_${Date.now()}_${context.state.guestData.email}.png`;
        const imageRef = storageRef.child(fileName);
        imageRef.put(payload.file)
          .then((snapshot) => {
            snapshot.ref.getDownloadURL()
              .then((downloadURL) => {
                resolve({ url: downloadURL, fileName });
              })
              .catch((error) => {
                reject(error);
              });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteResponseImage(context, payload) {
      return new Promise((resolve, reject) => {
        const storageRef = firebase.storage().ref();
        const imageRef = storageRef.child(payload.fileName);
        imageRef.delete(payload.file)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  mutations: {
    setUserData(state, payload) {
      const userObject = JSON.parse(payload.result.data);
      state.userData = userObject.user;
    },
    updateUserData(state, payload) {
      state.userData = payload;
    },
    setGuestData(state, payload) {
      state.guestData = payload;
    },
    setLoggedIn(state, status) {
      state.loggedIn = status;
    },
    clearUserData(state) {
      state.userData = {};
      state.jwt = '';
      // console.log(state.userData);
    },
    setJwtToken(state, payload) {
      // console.log('setJwtToken payload = ', payload);
      localStorage.token = payload.jwt.token;
      state.jwt = payload.jwt;
    },
    setAssignment(state, payload) {
      state.selectedAss = payload.assID;
    },
    setRespInfo(state, payload) {
      state.respInfo = payload.respInfo;
    },
    setEmailVerified(state, payload) {
      state.emailVerified = payload.emailVerified;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload.currentUser;
    },
    setError(state, payload) {
      state.error = payload.status;
    },
    setLoadScreen(state, payload) {
      state.loadScreen = payload.status;
    },
  },
});
