<template>
  <section class="row g-0 min-vh-100 p-0">
    <div class="col-lg-4 justify-content-end d-flex bg-rust-gradient">
      <img src="~@/assets/waffle_flakes.png" class="bg-image">
      <div class="divider-login divider-vertical d-none d-lg-block">
        <svg width='100%' height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
          <path d="M50,0 L100,0 L100,100 L50,100 C83.333333,83.3333333 100,66.6666667
          100,50 C100,33.333333 83.33333333,16.6666667 50,0 Z" fill="#F3ECe6">
          </path>
        </svg>
      </div>
    </div>
    <div class="col d-flex align-self-center register-col p-5">
      <div class="container my-auto register-card text-center">
        <form @submit="submitForm">
          <h1 class="text">Welcome to Waffle</h1>
          <h5 class="text">Redefine Education. Today.</h5>
          <div>
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label fs-5">Email</label>
              <input type="text" name="email"
              class="input-field" v-model="email"
              :class="{ 'is-invalid': submitted && $v.email.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.email.required" class="error">
              Email is required</div>
              <div v-if="submitted && !$v.email.email" class="error">
              Email is invalid</div>
            </div>
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label fs-5">Password</label>
              <input type="password" name="password"
              class="input-field" v-model="secret"
              :class="{ 'is-invalid': submitted && $v.secret.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.secret.required"
              class="error">Password is required</div>
            </div>
            <div v-if="invalid" class="error mt-2 mb-2 text-left">
              <small class="fw-bold"> Email or Password is incorrect. Please try again.</small>
            </div>
            <div class="row">
              <div class="col">
                <router-link to="/forgotpassword" class="float-start text-primary pl-2">
                Forgot Password?</router-link>
              </div>
              <div class="col bg-gradient-primary text-white">
                <button type="submit"
                class=" float-end btn bg-pri text-center" @click="submitForm">
                  Login
                </button>
              </div>
            </div>
            <p class="fs-5"> or
              <button @click="checkGoogleUser" class="social-button mt-2">
                <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
              </button>
            </p>
            <div class="form-group">
              <a class="text-right text-primary pl-2" href="/register">
                Don't have an account? Register now!
              </a>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios';
import {
  required, email,
} from 'vuelidate/lib/validators';
import firebase from 'firebase';

export default {
  name: 'Login',
  components: {
  },
  data() {
    return {
      email: '',
      secret: '',
      submitted: false,
      googleSubmitted: false,
      invalid: false,
      userData: {},
      resetEmail: null,
      emailVerified: null,
    };
  },
  validations: {
    email: { required, email },
    secret: { required },
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    submitForm(event) {
      event.preventDefault();
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          email: this.email,
          password: this.secret,
        };
        this.$store.dispatch('login', payload)
          .then(() => {
            this.emailVerified = true;
            this.$store.commit('setLoadScreen', { status: false });
            this.$router.replace('/');
            // console.log(this.$store.state.userData);
          })
          .catch((err) => {
            this.invalid = true;
            this.$store.commit('setLoadScreen', { status: false });
            console.log(err);
          });
      }
    },
    checkGoogleUser(event) {
      event.preventDefault();
      this.googleSubmitted = true;
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      firebase.auth().signInWithPopup(provider)
        .then((result) => {
          this.$store.commit('setLoadScreen', { status: true });
          // const token = result.credential.idToken;
          const signDate = new Date();
          const { user } = result;
          // console.log(user, token);
          this.userData = {
            name: user.displayName,
            email: user.email,
            tier: this.selectTier,
            fId: user.uid,
            signUpDate: signDate,
            lastLogin: signDate,
          };
          // console.log(this.userData);
          axios.post('/api/checkUser', { email: user.email })
            .then((res) => {
              if (res.data.valid === 1) {
                this.$router.replace('/register');
                this.$store.commit('setLoadScreen', { status: false });
              } else {
                this.googleLogin();
                this.$store.commit('setLoadScreen', { status: false });
              }
            });
        });
    },
    googleLogin() {
      this.$store.dispatch('googleLogin', this.userData)
        .then(() => {
          this.$router.replace('/');
          this.$store.commit('setLoadScreen', { status: false });
          // console.log(this.$store.state.userData);
        }).catch(() => {
          this.invalid = false;
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
  },
  mounted() {
  },

};
</script>

<style scoped>
.error {
  color: red;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}

#email-field i {
  position: absolute;
  top: 270px;
  right: 30px;
  /*min-width: 40px;*/
}

label {
  width: 100%;
}

.card-select {
  display: none;
}

.card-input {
  margin: 00px;
  height: 100%;
  width: 100%;
  border-radius: 2em;
}

.card-input:hover {
  cursor: pointer;
  box-shadow: .25rem .25rem rgba(20,1,1,0.075);
}

.card-select:checked + .card-input {
  background-color: #E97852;
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
 }

.divider-login {
  position: relative;
  z-index: 0; }
  .divider-login svg {
    background: none !important;
    height: 6vw; }
    .divider-login svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-login:not(:first-child) {
    top: 1px; }
  .divider-login:first-child {
    bottom: 1px; }
  .divider-login.divider-vertical {
    height: 100vh;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-login.divider-vertical svg {
      height: 100vh;
      width: 25rem; }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0; }

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.register-col {
  z-index: 1;
  position: relative;
  background-color: #f3ece6;
  height: 100vh;
}

.register-card {
  max-width: 50%;
}

.bg-rust-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(159deg, rgba(187,75,37,1) 0%, rgba(233,120,82,1) 50%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}
.social-button {
  border: 0;
}

.input-container{
  /*display: inline-block;*/
  text-align: center;
}
.input-field{
  width: 100%;
  min-height: 1em;
  border: none;
  /*outline: none;*/
  background-color: rgba(187,75,37,0.1);
  border-radius: 3em;
  padding: 0.1em 1em;
}

.input-border{
  margin-left: auto;
  margin-right: auto;
  display:inline-block;
  width: 0px;
  height: 0px;
  background: #87ceeb;
  position: relative;
  top:-2px;
  -webkit-transition: all ease-in-out .15s;
  -o-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
}
.input-field:focus {
  outline:  none;
  background-color: rgba(135,206,235,.15);
}

.input-field:focus ~ .input-border {
  height:  2px;
  width: calc(100% - 2em);
}
</style>
