import Vue from 'vue';
import Vuelidate from 'vuelidate';
import axios from 'axios';
import firebase from 'firebase';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/css/bootstrap.css';

const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG);

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

axios.interceptors.response.use((response) => response, (error) => {
  this.$store.commit('setLoadScreen', { status: false });
  store.commit('setError', { status: true });
  console.log('error');
  return Promise.reject(error);
});

Vue.config.productionTip = false;
Vue.use(Vuelidate);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
