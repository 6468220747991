<template>
  <div>
    <div v-if="assignment">
      <div class="row min-vh-100 mx-0">
        <div class="col-8 mx-auto align-self-center">
          <div class="card p-3 rounded shadow">
            <h4> Assessment Summary </h4>
            <div class="row">
              <div class="col text-end">
                <span> Title </span>
              </div>
              <div class="col">
                <h5> {{assignment.title}} </h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <span> Type </span>
              </div>
              <div class="col">
                <h5> {{assignment.aType.type}} </h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <span> Start Time </span>
              </div>
              <div class="col">
                <h5> {{startTime}} </h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <span> Due Time </span>
              </div>
              <div class="col">
                <h5> {{dueTime}} </h5>
              </div>
            </div>
            <div class="row" v-if="assignment.aType.type != 'Homework'">
              <div class="col text-end">
                <span> Allowed Attempts </span>
              </div>
              <div class="col">
                <h5> {{assignment.att}} </h5>
              </div>
            </div>
            <div class="row">
              <div class="col text-end">
                <span> Status </span>
              </div>
              <div class="col">
                <h5 v-if="status == 'Not open'">
                  Not Open
                </h5>
                <h5 v-else-if="status == 'Open'">
                  Open
                </h5>
                <h5 v-else>
                  Closed
                </h5>
              </div>
            </div>
            <div v-if="respInfo">
              <hr />
              <h4> Student Summary </h4>
              <div class="row">
                <div class="col text-end">
                  <span> Name </span>
                </div>
                <div class="col">
                  <h5> {{respInfo.guestInfo.givenName}} {{respInfo.guestInfo.lastName}} </h5>
                </div>
              </div>
              <div class="row">
                <div class="col text-end">
                  <span> email </span>
                </div>
                <div class="col">
                  <h5> {{respInfo.guestEmail}} </h5>
                </div>
              </div>
              <div class="row" v-show="assignment.aType.type != 'Homework'">
                <div class="col text-end">
                  <span> Attempts Remaining </span>
                </div>
                <div class="col">
                  <h5> {{attemptsRemaining}} </h5>
                </div>
              </div>
            </div>
          </div>
          <div class="d-grid mt-2">
            <div class="row mx-0" v-if="respInfo">
              <button class="col btn btn-lg btn-primary me-1" @click="enterReview"
              :disabled="!assignment.releaseGrades">
                Review Grades
              </button>
              <button class="col btn btn-lg btn-success ms-1" @click="enterAssignment"
              :disabled="(attemptsRemaining < 1 && attemptsRemaining !== -9999)
                || assignment.releaseGrades || !open">
                Begin Assignment
              </button>
            </div>
            <button class="btn btn-lg btn-success" @click="mountCredModal" v-else>
              Enter Credentials
            </button>
            <div class="row">
              <small class="col d-inline"
                v-if="attemptsRemaining  < 1 && attemptsRemaining !== -9999">
                You have insufficient attempts remaining
              </small>
              <small class="col text-end text-decoration-underline text-primary"
                @click="resetCredentials" type="button" v-show="respInfo">
                Not you? Reset credentials.
              </small>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="credentialModal" tabindex="-1"
        aria-labelledby="credentialLabel" aria-hidden="true">
        <div class="modal-dialog">
          <form class="needs-validation" id="studentCredentials"
              novalidate @submit.prevent @submit="enterCredentials()">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="credentialLabel">
                  Enter Your Information
                </h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
                </button>
              </div>
              <div class="modal-body">
                <div class="mb-3">
                  <label for="email" class="form-label">
                    Email address
                  </label>
                  <input type="email" class="form-control"
                    id="studentEmail" placeholder="name@example.com"
                    v-model="studentEmail" required>
                    <div class="invalid-feedback">
                      Please enter a valid email.
                    </div>
                </div>
                <div class="mb-3">
                  <label for="studentGivenName" class="form-label">
                    Given Name (as per your ID)
                  </label>
                  <input type="text" class="form-control"
                    id="studentGivenName" placeholder="e.g: John David"
                    v-model="studentGivenName" required>
                    <div class="invalid-feedback">
                      Please provide a Given Name.
                    </div>
                </div>
                <div class="mb-3">
                  <label for="studentLastName" class="form-label">
                    Last Name (as per your ID)
                  </label>
                  <input type="text" class="form-control"
                    id="studentLastName" placeholder="e.g: Doe"
                    v-model="studentLastName" required>
                    <div class="invalid-feedback">
                      Please provide a Last Name.
                    </div>
                </div>
                <div class="mb-3">
                  <label for="passcode" class="form-label">
                    Access Code
                  </label>
                  <input type="text" class="form-control"
                    :class="validToken && passcode.length > 0
                    ? 'is-valid':'is-invalid'"
                    id="passcode" placeholder="e.g: 60ab828640640e773cd1b7c7"
                    v-model="passcode">
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value=""
                  id="invalidCheck" required>
                  <label class="form-check-label" for="invalidCheck">
                    All information provided is accurate to the best of my knowledge.
                  </label>
                  <div class="invalid-feedback">
                    You must agree before submitting.
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close
                </button>
                <button type="submit" class="btn btn-primary">Proceed</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import axios from 'axios';
import { Modal } from 'bootstrap';

export default {
  name: 'TakeLanding',
  computed: {
    startTime() {
      const tempStart = new Date(this.assignment.schedUTC);
      const startString = tempStart.toLocaleString('en-UK', {});
      // tempStart = `${tempStart.toISOString().slice(0, 10).replace(/-/g, '/')}
      //  ${tempStart.toISOString().substr(11, 5)}`;
      return startString;
    },
    dueTime() {
      let tempStart = new Date(this.assignment.schedUTC);
      tempStart = tempStart.getTime();
      const tempDue = tempStart + this.assignment.totTime * 60000;
      // tempDue = `${new Date(tempDue).toISOString().slice(0, 10).replace(/-/g, '/')}
      //  ${new Date(tempDue).toISOString().substr(11, 5)}`;
      const dueString = new Date(tempDue).toLocaleString('en-UK', {});
      return dueString;
    },
    validToken() {
      return this.assignment.passcode === this.passcode;
    },
    attemptsRemaining() {
      if (this.respInfo && this.assignment.aType.type !== 'Homework') {
        const remaining = parseInt(this.assignment.att, 10)
        - parseInt(this.respInfo.attempts, 10);
        if (remaining > 0) {
          return remaining;
        }
        return 0;
      }
      return -9999;
    },
    respInfo() {
      return this.$store.state.respInfo;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      assignment: null,
      assID: null,
      studentEmail: '',
      studentLastName: '',
      studentGivenName: '',
      passcode: '',
      credentialModal: null,
      checkInterval: null,
      open: false,
    };
  },
  methods: {
    getAssignment() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/assessment/getassessmentinfo', {
        params: {
          aId: this.assID,
        },
      })
        .then((response) => {
          this.assignment = JSON.parse(response.data.assignmentInfo);
          this.checkAssignment();
          this.checkInterval = setInterval(() => {
            this.checkAssignment();
          }, 30000);
          if (this.respInfo) {
            this.studentEmail = this.respInfo.guestEmail;
            this.studentGivenName = this.respInfo.guestInfo.givenName;
            this.studentLastName = this.respInfo.guestInfo.lastName;
            this.setStudentProfile();
          }
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    checkAssignment() {
      const openDate = new Date(this.assignment.schedUTC).getTime() / 1000;
      const dueDate = openDate + (this.assignment.totTime * 60);
      const now = new Date().getTime() / 1000;

      if (now < openDate) {
        this.status = 'Not open';
        this.open = false;
      } else if (now < dueDate) {
        this.status = 'Open';
        this.open = true;
      } else {
        this.status = 'Closed';
        this.open = false;
      }
    },
    mountCredModal() {
      this.credentialModal = new Modal(document.getElementById('credentialModal'),
        {});
      this.credentialModal.show();
    },
    enterCredentials() {
      const form = document.getElementById('studentCredentials');
      // console.log(form.checkValidity());
      form.classList.add('was-validated');
      if (this.validToken) {
        if (form.checkValidity()) {
          this.$store.commit('setLoadScreen', { status: true });
          this.$store.commit('setGuestData', {
            givenName: this.studentGivenName,
            lastName: this.studentLastName,
            email: this.studentEmail,
          });
          this.setStudentProfile();
        }
      }
    },
    setStudentProfile() {
      axios.post('/api/assessment/createresponse', {
        aId: this.assID,
        guestEmail: this.studentEmail,
        guestInfo: {
          givenName: this.studentGivenName,
          lastName: this.studentLastName,
        },
      })
        .then((response) => {
          if (this.credentialModal) {
            this.credentialModal.hide();
          }
          const respInfo = JSON.parse(response.data.respInfo);
          delete respInfo.response;
          this.$store.commit('setRespInfo', { respInfo: JSON.parse(response.data.respInfo) });
          this.$store.commit('setLoadScreen', { status: false });
          // console.log(this.respInfo);
          // this.$router.push('room');
        });
    },
    enterAssignment() {
      this.$router.push('room');
    },
    enterReview() {
      this.$router.push('review');
    },
    resetCredentials() {
      this.$store.commit('setRespInfo', { respInfo: null });
    },
  },
  mounted() {
    this.assID = this.$route.params.id;
    this.getAssignment();
  },
  beforeRouteLeave(to, from, next) {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
    next();
  },
};
</script>
<style scoped>
</style>
