<template>
  <div class="row mx-0 p-5 justify-content-center bg-overlay">
    <div class="col-lg-6 col-md-7 col-sm-9 col-xs-8 align-self-center">
      <div style = "border-radius:1em;" class="card p-2 shadow border-0">
        <div class="col-lg-10 mx-auto align-self-center my-3 text-center">
          <h2 class="text">Reset your password</h2>
          <form @submit="submitReset">
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label p-0 fs-5">Enter new password</label>
              <input type="password" name="newPassword" placeholder="New Password"
              class="input-field fs-6" v-model="newPassword"
              :class="{ 'is-invalid': resetSubmitted && $v.newPassword.$error }"/>
              <span class="input-border"></span>
              <!-- <div class="form-text">
                Your password must be 8 characters long
              </div> -->
              <div v-if="resetSubmitted && !$v.newPassword.required" class="form-text error">
              Password is required</div>
              <div v-if="resetSubmitted && !$v.newPassword.minLength" class="form-text error">
              Password must be at least 8 characters long</div>
              <div v-if="resetSubmitted && !$v.newPassword.valid && newPassword"
              class="form-text error">
              Password must contain Uppercase and lowercase characters, numbers and symbols</div>
            </div>
            <div class="text-start text-secondary my-4 row input-container">
              <label class="form-label p-0 fs-5" >Confirm new password</label>
              <input type="password" name="confirmpassword" placeholder="Confirm"
              class="input-field fs-6" v-model="confirmPassword"
              :class="{ 'is-invalid': resetSubmitted && $v.confirmPassword.$error }"/>
              <span class="input-border"></span>
              <div v-if="resetSubmitted && !$v.confirmPassword.required"
              class="form-text error">Confirm Password is required</div>
              <div v-if="resetSubmitted && !$v.confirmPassword.sameAsPassword"
              class="form-text error">Passwords do not match</div>
            </div>
            <div class="col bg-gradient-primary text-white">
              <button type="submit"
              class=" float-end btn button-color btn text-center" @click="submitReset">
                Confirm Reset
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required, minLength, sameAs,
} from 'vuelidate/lib/validators';
import firebase from 'firebase';

export default {
  name: 'PasswordReset',
  data() {
    return {
      newPassword: '',
      confirmPassword: '',
      resetSubmitted: false,
      invalid: false,
      code: null,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  validations: {
    newPassword: {
      required,
      minLength: minLength(8), // I assume you'd want something like this too
      valid(value) {
        const containsUppercase = /[A-Z]/.test(value);
        const containsLowercase = /[a-z]/.test(value);
        const containsNumber = /[0-9]/.test(value);
        const containsSpecial = /[#?!@$%^&*-]/.test(value);
        return (
          containsUppercase
          && containsLowercase
          && containsNumber
          && containsSpecial
        );
      },
    },
    confirmPassword: { required, sameAsPassword: sameAs('newPassword') },
  },
  methods: {
    submitReset(event) {
      event.preventDefault();
      this.resetSubmitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        firebase.auth().confirmPasswordReset(this.code, this.newPassword)
          .then(() => {
            this.$router.replace('/login');
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch(() => {
            this.invalid = true;
            this.$store.commit('setLoadScreen', { status: false });
          });
      }
    },
  },
  mounted() {
    this.code = this.$route.query.oobCode;
  },
};
</script>

<style scoped>
  .error {
  color: red;
  font-size: 0.8em;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}

</style>
