<template>
  <div class="row mx-0 p-5 justify-content-center bg-overlay">
    <div class="col-lg-6 col-sm-9 col-xs-8 align-self-center">
      <div style = "border-radius:1em;" class="card p-2 shadow border-0">
        <div class="col-lg-6 mx-auto align-self-center my-3 text-center">
          <p>
          Your email has not been verified.
          </p>
          <div class="bg-gradient-primary mb-4 text-white">
            <button type="submit"
            class=" float-center btn button-color btn text-center"
            @click="sendEmail">
              Resend Email
            </button>
          </div>
          <p>
            Please follow the instructions in the email to verify your email.
            You can log in once your email is verified.
          </p>
          <div class="bg-gradient-primary text-white">
            <button type="submit"
            class=" float-center btn button-color btn text-center"
            @click="$router.push('/login')">
              Login
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

// import firebase from 'firebase';

export default {
  name: 'EmailUnverified',
  components: {
  },
  data() {
    return {
      code: null,
      verified: false,
      email: null,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    sendEmail() {
      this.$store.commit('setLoadScreen', { status: true });
      const { userData, currentUser } = this.$store.state;
      // console.log(userData);
      this.email = userData.email;
      // console.log(this.email);
      // firebase.auth().currentUser.getIdToken(false)
      // .then(() => {
      currentUser.sendEmailVerification()
        .then(() => {
          this.$store.commit('setLoadScreen', { status: false });
          // console.log('Verification email sent');
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
  },
  mounted() {
  },
};
</script>

<style scoped>
.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}
</style>
