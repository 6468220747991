<template>
  <div class="bg-overlay">
    <div v-if="verified===false" class="row mx-0 p-5">
      <div class="col-lg-6 mx-auto align-self-center my-auto text-center">
        <p>
        Verifying your email, please wait a moment
        </p>
  <!--       <div class="bg-gradient-primary text-white">
          <button type="submit"
          class=" float-center btn button-color btn text-center"
          @click="$router.push('/login')">
            Login
          </button>
        </div> -->
      </div>
    </div>
    <div v-else class="row mx-0 p-5">
      <div class="col-lg-6 mx-auto align-self-center my-auto text-center">
        <p>
        Your email has been verified, please Login
        </p>
        <div class="bg-gradient-primary text-white">
          <button type="submit"
          class=" float-center btn button-color btn text-center"
          @click="$router.push('/login')">
            Login
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import firebase from 'firebase';

export default {
  name: 'VerifyEmail',
  data() {
    return {
      code: null,
      verified: false,
    };
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    submit() {
      this.$store.commit('setLoadScreen', { status: true });
      firebase.auth().applyActionCode(this.code)
        .then(() => {
          // Email address has been verified.
          this.verified = true;
          this.$store.commit('setLoadScreen', { status: false });
          // console.log(resp);
          // TODO: Display a confirmation message to the user.
          // You could also provide the user with a link back to the app.

          // TODO: If a continue URL is available, display a button which on
          // click redirects the user back to the app via continueUrl with
          // additional state determined from that URL's parameters.
        }).catch((error) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(error);
        // Code is invalid or evxpired. Ask the user to verify their email address
        // again.
        });
    },
  },
  mounted() {
    this.code = this.$route.query.oobCode;
    // console.log(this.code);
    this.submit();
  },
};
</script>
<style scoped>
.error {
  color: red;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}
</style>
