<template>
  <div class="bg-overlay">
    <Navbar></Navbar>
    <div class="col-10 mx-auto py-4">
      <div class="modal fade" id="detailsModal"
      tabindex="-1" role="dialog" v-show="editFocus">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit assignment details</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <form>
                <label for="copyName">Title</label>
                <input type="text" class="form-control" id="copyName"
                v-model="edit.editTitle"
                :class="{ 'is-invalid': submitEdit && $v.edit.editTitle.$error }"/>
                <div v-if="submitEdit && !$v.edit.editTitle.required"
                class="form-text error">Title is required
                </div>
                <div>
                  <label class="mt-2" for="ass_date"> Release Time </label><br/>
                  <datetime type="datetime" id="ass_date" v-model="releaseDate"
                  @close="calcDuration()">
                  </datetime>
                </div>
                <div v-if="editFocus.aType && editFocus.aType.id==1">
                  <label class="mt-2" for="rel_date"> Due Time </label><br />
                  <datetime type="datetime" id="rel_date" v-model="dueDate"
                  @close="calcDuration()">
                  </datetime>
                  <div v-if="submitEdit && !$v.dueDate.minValue"
                    class="error form-text">
                      Due Time must be after Release Time
                  </div>
                </div>
                <div v-else>
                  <div>
                    <label class="mt-2" for="dur"> Duration </label><br />
                    <input v-model="edit.editDuration" id="dur" autocomplete="off"
                    type="number" step="1" class="form-control d-inline"
                    :class="{ 'is-invalid': submitEdit && $v.edit.editDuration.$error }"/>
                    <div v-if="submitEdit && !$v.edit.editDuration.required"
                    class="error form-text">
                      Duration is required
                    </div>
                    <div v-if="submitEdit && !$v.edit.editDuration.minValue"
                    class="error form-text">
                      Please enter a positive number
                    </div>
                  </div>
                  <div>
                    <label class="mt-2" for="att"> Attempts allowed </label><br />
                    <input v-model="edit.editAttempts" id="att" autocomplete="off"
                    type="number" step="1" class="form-control d-inline"
                    :class="{ 'is-invalid': submitEdit && $v.edit.editAttempts.$error }"/>
                    <div v-if="submitEdit && !$v.edit.editAttempts.required"
                    class="error form-text">
                      Number of Attmepts is required
                    </div>
                    <div v-if="submitEdit && !$v.edit.editAttempts.minValue"
                    class="error form-text">
                      Please enter a positive number
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button v-if="save=='Save'" type="button" class="btn bg-pri"
              v-on:click="updateDetails()">Save</button>
              <button v-else-if="save=='Saving...'" type="button" class="btn btn-warn"
              v-on:click="updateDetails()">Saving...</button>
              <button v-if="save=='Saved!'" type="button" class="btn btn-success"
              v-on:click="updateDetails()">Saved!</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="copyAssModal"
        tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Copy assignment</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <label for="copyName">Enter name of copy</label>
              <input type="text" class="form-control" v-model="copy.copyName"
              :class="{ 'is-invalid': submitCopy && $v.copy.copyName.$error }"/>
              <div v-if="submitCopy && !$v.copy.copyName.required"
              class="form-text error">Copy Name is required
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
                data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-pri"
                v-on:click="copyAssessment()">
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="errorModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="errorModalLabel">
              You are not authorized to execute this action.</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">OK</button>
            </div>
          </div>
        </div>
      </div>
      <div class="modal fade" id="deleteAssModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="deleteAssModalLabel">
              This action cannot be undone</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
                </button>
            </div>
            <div class="modal-body">
              Do you wish to proceed?
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-ter"
              v-on:click="deleteAssessment()">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="row ms-0 align-items-center mb-4">
          <h1 class="col text-start">Assignments</h1>
          <div class="col text-end">
            <div v-if="subscriptionLimit">
              <span class="text-muted" v-if="createAssessmentAllowed">
                {{ assessmentsRemaining }} remaining
              </span>
              <span class="text-danger" v-else>No assignments remaining</span>
              <span class="material-icons align-middle"
              data-bs-toggle="tooltip" title="Your school's subscription allows max.
              20 assignments per teacher"
              style="font-size: 16px;">info</span>
            </div>
            <button class="btn btn-inline" v-on:click="loadAssCreation"
              v-bind:disabled="!createAssessmentAllowed">
              <i class="align-middle material-icons md-32 md-light">add_circle_outline</i>
            </button>
          </div>
        </div>
        <div class="accordion" id="accordionTasks">
          <div class="accordion-item">
            <h4 class="accordion-header accordion-title" id="publishedTitle">
              <button class="accordion-button" type="button"
                data-bs-toggle="collapse"
                data-bs-target="#publishedTable" aria-expanded="true"
                aria-controls="publishedTable">
                Published
              </button>
            </h4>
            <div id="publishedTable" class="accordion-collapse collapse show"
              aria-labelledby="publishedTitle" data-bs-parent="#accordionTasks">
              <div class="bg-white p-0 accordion-card rounded shadow">
                <table class="p-2 table scroll-table" id="assTable">
                  <thead class="bg-dark text-white">
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Due date</th>
                      <th>Status</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="assObj.length>0">
                    <tr v-for="ass, key in assObj" :key="key">
                      <td style="font-weight: bold;">{{ ass.title }}</td>
                      <td>{{ ass.aType.type }}</td>
                      <td>{{ assStatus[ass._id.$oid].dueDate }}</td>
                      <td>
                        <span v-if="assStatus[ass._id.$oid].status == 'Not open'">
                          <i class="material-icons align-middle md-16 me-1">schedule</i>
                          <span class="text-small align-middle">
                            {{ prettyDate(ass.schedUTC) }}
                          </span>
                        </span>
                        <span v-else-if="assStatus[ass._id.$oid].status == 'Open'">
                          {{ subList[ass._id.$oid] }} submission(s)
                        </span>
                        <span v-else>
                          Closed
                        </span>
                      </td>
                      <td class="text-end">
                        <button v-if="assStatus[ass._id.$oid].status == 'Open'"
                          class="btn btn-light mx-1" v-on:click="loadAssMonitor(ass._id.$oid)">
                          <i class="material-icons align-middle" data-bs-toggle="tooltip"
                            title="Monitor">
                            visibility
                          </i>
                        </button>

                        <button v-if="assStatus[ass._id.$oid].editable ||
                          subList[ass._id.$oid] == 0"
                          class="btn btn-light mx-1" v-on:click="loadAssessment(ass._id.$oid)">
                          <i class="material-icons align-middle" data-bs-toggle="tooltip"
                            title="Edit">
                            edit
                          </i>
                        </button>
                        <button v-if="!ass.releaseGrades && assStatus[ass._id.$oid].gradable"
                          class="btn btn-light mx-1" v-on:click="releaseGrades(ass._id.$oid,true)">
                          <i class="material-icons align-middle" data-bs-toggle="tooltip"
                            title="Release Grades">
                            lock
                          </i>
                        </button>
                        <button v-else-if="ass.releaseGrades && assStatus[ass._id.$oid].gradable"
                          class="btn btn-light mx-1" v-on:click="releaseGrades(ass._id.$oid,false)">
                          <i class="material-icons align-middle" data-bs-toggle="tooltip"
                            title="Lock Grades">
                            lock_open
                          </i>
                        </button>
                        <button v-if="assStatus[ass._id.$oid].gradable" class="btn btn-light mx-1"
                          v-on:click="gradeAssessment(ass._id.$oid)">
                          <i class="material-icons align-middle" data-bs-toggle="tooltip"
                            title="Grade">grading</i>
                        </button>
                        <div class="dropdown d-inline">
                          <button class="dropdown-toggle btn btn-light" data-bs-toggle="dropdown">
                            <i class="material-icons align-middle" data-bs-toggle="tooltip"
                              title="More">
                              more_vert
                            </i>
                          </button>
                          <div class="dropdown-menu dropdown-menu-end">
                            <button class="dropdown-item btn btn-light"
                            @click="loadAssessmentDetails(ass)">
                              Edit Details
                            </button>
                            <button class="dropdown-item btn btn-light"
                              @click="loadAssessmentCopy(ass)">
                              Copy
                            </button>
                            <button class=" dropdown-item btn text-white bg-danger"
                              v-on:click="loadDelete(ass)">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        No assignments to display.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-4 accordion-item">
            <h4 class="accordion-header accordion-title" id="draftTitle">
              <button class="accordion-button collapsed"
                type="button" data-bs-toggle="collapse"
                data-bs-target="#draftAccordion" aria-expanded="false"
                aria-controls="draftAccordion" @click="mountDrafts">
                Drafts
              </button>
            </h4>
            <div id="draftAccordion" class="accordion-collapse collapse mt-2"
              aria-labelledby="draftTitle" data-bs-parent="#accordionTasks">
              <div class="bg-white p-0 accordion-card rounded shadow">
                <table class="p-2 table scroll-table" id="draftTable">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th class="text-center">Actions</th>
                    </tr>
                  </thead>
                  <tbody v-if="draftObj.length>0">
                    <tr v-for="ass, key in draftObj" :key="key">
                      <td style="font-weight: bold;">{{ ass.title }}</td>
                      <td>{{ ass.aType.type }}</td>
                      <td class="text-end">
                        <button class="btn btn-light mx-1"
                          @click="loadAssessment(ass._id.$oid)">
                          <i class="material-icons align-middle"
                            data-bs-toggle="tooltip" title="Edit">edit</i>
                        </button>
                        <div class="dropdown d-inline">
                          <button class="dropdown-toggle btn btn-light mx-1"
                            data-bs-toggle="dropdown">
                            <i class="material-icons align-middle"
                              data-bs-toggle="tooltip" title="More">more_vert</i>
                          </button>
                          <div class="dropdown-menu">
                            <button class="dropdown-item btn btn-light"
                            @click="loadAssessmentDetails(ass)">
                              Edit Details
                            </button>
                            <button class="dropdown-item btn btn-light"
                              @click="loadAssessmentCopy(ass)">
                              Copy
                            </button>
                            <button class=" dropdown-item btn text-white bg-danger"
                              v-on:click="loadDelete(ass)">
                              Delete
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="5" class="text-center">
                        No assignments to display.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import { Datetime } from 'vue-datetime';
import $ from 'jquery';
import 'vue-datetime/dist/vue-datetime.css';
import {
  required,
} from 'vuelidate/lib/validators';
import 'datatables.net';
import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
import Navbar from '../components/Navbar.vue';

export default {
  name: 'Dashboard',
  props: ['userData', 'teacher'],
  components: {
    Datetime,
    Navbar,
  },
  computed: {
    userID() {
      return this.$store.state.userData.fId;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      assObj: {},
      draftObj: {},
      editFocus: {
        title: null,
        type: null,
      },
      assFocus: null,
      courseFocus: null,
      assStatus: {},
      intervalFunction: null,
      createAssessmentAllowed: true,
      subscriptionLimit: false,
      assessmentsRemaining: null,
      courses: {},
      copyCourse: null,
      copy: {
        copyName: null,
      },
      save: null,
      editModal: null,
      copyModal: null,
      deleteModal: null,
      releaseDate: null,
      subList: [],
      pubTable: null,
      draftTable: null,
      submitEdit: false,
      submitCopy: false,
      edit: {
        editTitle: null,
        editDuration: null,
        editAttempts: null,
      },
      dueDate: null,
    };
  },
  validations: {
    edit: {
      editTitle: { required },
      editDuration: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
      editAttempts: {
        required,
        minValue(val) {
          return val > 0;
        },
      },
    },
    dueDate: {
      minValue(val) {
        return new Date(val) > new Date(this.releaseDate);
      },
    },
    copy: {
      copyName: { required },
    },
  },
  methods: {
    viewCourse(courseid) {
      this.clearTimer();
      this.courseFocus = courseid;
      this.updateStatus();
      this.intervalFunction = setInterval(this.updateStatus, 10000);
    },
    prettyDate(date) {
      const tempDate = new Date(date);
      // console.log(date);
      const month = this.checkDigit(tempDate.getMonth() + 1);
      const day = this.checkDigit(tempDate.getDate());
      const year = this.checkDigit(tempDate.getFullYear());
      const hour = this.checkDigit(tempDate.getHours());
      const minute = this.checkDigit(tempDate.getMinutes());

      return `${year}/${month}/${day} ${hour}:${minute}`;
    },
    checkDigit(val) {
      if (String(val).length < 2) { return `0${val}`; }
      return val;
    },
    clearTimer() {
      clearInterval(this.intervalFunction);
    },
    updateStatus() {
      /* eslint-disable no-underscore-dangle */
      this.assObj.forEach((ass) => {
        const openDate = new Date(ass.schedUTC).getTime() / 1000;
        const dueDate = openDate + (ass.totTime * 60);
        const now = new Date().getTime() / 1000;

        if (now < openDate) {
          this.assStatus[ass._id.$oid] = {
            editable: true,
            gradable: false,
            status: 'Not open',
          };
        } else if (now < dueDate) {
          this.assStatus[ass._id.$oid] = {
            editable: false,
            gradable: true,
            status: 'Open',
          };
        } else {
          this.assStatus[ass._id.$oid] = {
            editable: false,
            gradable: true,
            status: 'Closed',
          };
        }
        this.assStatus[ass._id.$oid].dueDate = this.calcDueDate(ass);
      });
      /* eslint-disable no-underscore-dangle */
      this.$forceUpdate();
    },
    loadAssCreation() {
      this.$store.commit('setAssignment', { assID: null });
      this.$router.push('/create');
    },
    loadAssMonitor(key) {
      this.$store.commit('setAssignment', { assID: key });
      this.$router.push('/monitor');
    },
    loadAssessment(key) {
      this.$store.commit('setAssignment', { assID: key });
      this.$router.push('/create');
    },
    gradeAssessment(key) {
      this.$store.commit('setAssignment', { assID: key });
      this.$router.push('/grade');
    },
    deleteAssessment() {
      axios.post('/api/assessment/deleteassessment', {
        assID: this.assFocus._id.$oid,
      }).then(() => {
        this.getAss();
        this.deleteModal.hide();
      }).catch((err) => {
        throw err;
      });
    },
    // axios.get(`/api/assessment/${key}/delete`)
    //   .then((response) => {
    //     if (response.data.message === 'success') {
    //       axios.get(`/api/assessment/course/${this.$route.params.id}`)
    //         .then(() => {
    //           this.assObj = response.data.assessments;
    //           this.draftObj = response.data.drafts;
    //           $('#deleteAssModal').modal('hide');
    //         });
    //     } else {
    //       $('#errorModal').modal('show');
    //     }
    //   });
    loadAssessmentTaking(key) {
      const pathvar = `/tasks/${key}/submit`;
      this.$router.push({ path: pathvar });
    },
    reviewAssessment(key) {
      const pathvar = `/tasks/${key}/review`;
      this.$router.push({ path: pathvar });
    },
    loadAssessmentCopy(ass) {
      this.copyModal = new Modal(document.getElementById('copyAssModal', {}));
      this.assFocus = ass;
      this.copy.copyName = `Copy of ${ass.title}`;
      this.copyModal.show();
    },
    loadDelete(key) {
      this.deleteModal = new Modal(document.getElementById('deleteAssModal', {}));
      this.assFocus = key;
      this.deleteModal.show();
    },
    copyAssessment() {
      this.submitCopy = true;
      if (!this.$v.copy.$invalid) {
        axios.post('/api/assessment/copyassessment', {
          aId: this.assFocus._id.$oid,
          title: this.copy.copyName,
        }).then(() => {
          this.getAss();
          this.copyModal.hide();
        });
      }
    },
    loadAssessmentDetails(ass) {
      this.editModal = new Modal(document.getElementById('detailsModal', {}));
      this.save = 'Save';
      this.editFocus = ass;
      this.releaseDate = ass.schedUTC;
      this.dueDate = ass.dueDate;
      this.edit.editTitle = ass.title;
      this.edit.editDuration = ass.totTime;
      this.edit.editAttempts = ass.att;
      if (parseInt(ass.aType.id, 10) === 1) {
        let tempR = new Date(this.releaseDate);
        tempR = tempR.getTime();
        this.dueDate = tempR + ass.totTime * 60000;
        this.dueDate = new Date(this.dueDate).toISOString();
      }
      this.editModal.show();
    },
    calcDuration() {
      if (parseInt(this.editFocus.aType.id, 10) === 1) {
        let releaseTemp = new Date(this.releaseDate);
        let dueTemp = new Date(this.dueDate);
        releaseTemp = releaseTemp.getTime();
        dueTemp = dueTemp.getTime();
        // console.log(releaseTemp);
        this.editFocus.totTime = (dueTemp - releaseTemp) / 60000;
      }
    },
    calcDueDate(ass) {
      let tempR = new Date(ass.schedUTC);
      tempR = tempR.getTime();
      let dueDate = tempR + ass.totTime * 60000;
      dueDate = this.prettyDate(new Date(dueDate));
      return dueDate;
    },
    updateDetails() {
      // this.editFocus.totTime = parseInt(this.editDuration, 10);
      // this.editFocus.att = parseInt(this.editAttempts, 10);
      // console.log(typeof this.editFocus.att);
      // console.log(typeof this.editFocus.totTime);
      this.submitEdit = true;
      if (!this.$v.edit.$invalid && (parseInt(this.editFocus.aType.id, 10) !== 1
        || (parseInt(this.editFocus.aType.id, 10) === 1 && !this.$v.dueDate.$invalid))) {
        axios.post('/api/assessment/editdetails', {
          aId: this.editFocus._id.$oid,
          updatedDetails: {
            schedUTC: new Date(this.releaseDate),
            totTime: this.edit.editDuration,
            title: this.edit.editTitle,
            att: this.edit.editAttempts,
          },
        })
          .then(() => {
            this.save = 'Saved!';
            this.editModal.hide();
            this.getAss();
          });
      }
    },
    mountDrafts() {
      if (this.draftTable === null) {
        this.draftTable = $('#draftTable').DataTable({
          fixedHeader: true,
          autoWidth: false,
          info: false,
          searching: false,
          paging: false,
          retrieve: true,
          ordering: true,
          columnDefs: [
            { orderable: false, targets: 2 },
          ],
        });
      }
    },
    releaseGrades(id, status) {
      axios.post('/api/assessment/releasegrades', {
        aId: id,
        status,
      })
        .then(() => {
          this.getAss();
        });
    },
    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.get('/api/assessment/getassessments', {
        params: {
          uID: this.userID,
        },
      })
        .then((response) => {
          this.assObj = JSON.parse(response.data.assignmentList);
          this.draftObj = JSON.parse(response.data.draftList);
          this.subList = response.data.submissionList;
          this.updateStatus();
          setTimeout(() => {
            this.pubTable = $('#assTable').DataTable({
              fixedHeader: true,
              autoWidth: false,
              info: false,
              searching: false,
              paging: false,
              retrieve: true,
              ordering: true,
              columnDefs: [
                { orderable: false, targets: 4 },
              ],
            });
          }, 10);
          this.$store.commit('setLoadScreen', { status: false });
          // this.viewCourse(Object.keys(this.assObj)[0]);
        // let calendarEl = document.getElementById("calendar");
        // var calendar = new FullCalendar.Calendar(calendarEl, {
        //   initialView: 'dayGridMonth',
        //   headerToolbar: {
        //     start: 'prev,next today',
        //     center: 'title',
        //     end: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek'
        //   }
        // });
        // calendar.render();
        }).catch((err) => {
          this.$store.commit('setLoadScreen', { status: false });
          console.log(err);
        });
    },
  },
  mounted() {
    // if (this.teacher && subscription === 'Snack') {
    //   this.subscriptionLimit = true;
    //   axios.get('/api/assessment/checklimit')
    //     .then((response) => {
    //       this.createAssessmentAllowed = (response.data.allowed === 1);
    //       this.assessmentsRemaining = response.data.remaining;
    //     });
    // }
    this.getAss();
  },
  beforeRouteLeave(to, from, next) {
    this.clearTimer();
    next();
  },
};
</script>

<style scoped>
.accordion-title button{
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(54,69,79,1) 0%,
    rgba(61,89,152,1) 47%, rgba(69,206,235,1) 100%);
}
.accordion-card{
  max-height: 23em !important;
  overflow-y: scroll;
}
.accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(-180deg);
}
.accordion-heading>a{
  display: block;
  color: #fff;
  text-decoration: none;
  font-size: 18px;
  padding: 0.5em;
}
.scroll-table{
  border-collapse: collapse;
  width: 100%;
  /*display: block;*/
}
.scroll-table tbody{
  font-size: 14px;
}
.scroll-table thead th{
  position: sticky;
  top: 0;
  z-index: 1;
  border:  0;

}

.scroll-table th {
  background-color: rgba(209,213,222);
  color: #0a0a0a;

}

.scroll-table td {
  vertical-align: middle;
}

.dropdown{
  position: inherit;
}
</style>
