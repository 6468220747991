<template>
<div>
  <loading :active.sync="loadScreen"
  :is-full-page="true"></loading>
  <nav class="navbar row mx-0 align-items-center">
    <div class="col ps-5">
      <a class="navbar-brand" href="/">
        <img src="~@/assets/logo_full_white.png" class="nav-logo">
        <span class="sub-brand"> assessments </span>
      </a>
    </div>
    <div class="col ms-auto text-end pe-5 dropdown">
      <div type="button" class="circle-span float-end" id="dropdownMenu" data-bs-toggle="dropdown">
        <span class="align-middle">{{initials}}</span>
      </div>
      <div class="dropdown-menu dropdown-menu-end p-0 me-1" aria-labelledby="dropdownMenu">
        <button class="dropdown-item"
        @click="loadAccount()">
          <i class="material-icons md-24">manage_accounts</i>
          Account
        </button>
        <button class="dropdown-item"
          @click="logOut()">
          <i class="material-icons md-24">logout</i>
          Log Out
        </button>
      </div>
    </div>
  </nav>
</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  name: 'navbar',
  components: {
    Loading,
  },
  data() {
    return {
      loadScreen: false,
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    initials() {
      const names = this.userData.name.split(' ');
      return `${names[0][0]}${names[1][0]}`;
    },
  },
  methods: {
    loadAccount() {
      this.$router.push('/account');
    },
    logOut() {
      this.loadScreen = true;
      this.$store.dispatch('logout')
        .then(() => {
          this.$router.push('/login');
          this.loadScreen = false;
        });
    },
  },
};
</script>

<style scoped>
.circle-span {
  height: 1.78em;
  width:  1.78em;
  background-color: #ffffff;
  border-radius: 50%;
  /*font-size: 18px;*/
  color: #455866;
  margin-bottom: 0.5em;
  text-align: center;
}
.nav-logo {
  height:  2em;
}
.navbar-brand {
  font-family: 'Nunito';
  font-stretch: extra-expanded;
  font-weight: 400;
  color: #fff;
  vertical-align: middle;
}
.navbar {
  background: linear-gradient(90deg, rgba(126,47,26,1) 0%,
    rgba(187,75,37,1) 50%, rgba(233,120,82,1) 100%);
}
.sub-brand {
  margin-left:  -0.5em;
}
</style>
