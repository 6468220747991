<template>
  <div class="row mx-0 p-5 justify-content-center bg-overlay">
    <div class="col-lg-6 col-sm-9 col-xs-8 align-self-center">
      <div style = "border-radius:1em;" class="card p-2 shadow border-0">
        <div class="col-lg-10 mx-auto align-self-center my-auto text-center">
          <div v-if="emailSubmitted===false" class="container my-3 text-center">
            <h2 class="text">Reset your password</h2>
            <form>
              <h5 class="text">Please Enter your Email</h5>
              <div class="text-start text-secondary my-4 row input-container">
                <label class="form-label fs-5">Email</label>
                <input type="text" name="email"
                class="input-field" v-model="email"
                :class="{ 'is-invalid': submitted && $v.email.$error }"/>
                <span class="input-border"></span>
                <div v-if="submitted && !$v.email.required" class="error">
                Email is required</div>
                <div v-if="submitted && !$v.email.email" class="error">
                Email is invalid</div>
                <div v-show="submitted && googleUser" class="error">
                Email is registered with Google. Please sign in with Google.</div>
              </div>
              <div class="col bg-gradient-primary text-white">
                <button type="button"
                class=" float-end btn button-color btn-lg text-center" @click="checkMethod">
                  Submit
                </button>
              </div>
            </form>
          </div>
          <div v-if="emailSubmitted===true">
            <h2 class="text">Reset your password</h2>
            <p>A reset email has been sent. Please check your inbox.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  required, email,
} from 'vuelidate/lib/validators';
import firebase from 'firebase';

export default {
  name: 'ForgotPassword',
  components: {
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      email: '',
      submitted: false,
      emailSubmitted: false,
      invalid: false,
      googleUser: null,
    };
  },
  validations: {
    email: { required, email },
  },
  methods: {
    submitEmail() {
      if (!this.googleUser) {
        const auth = firebase.auth();
        auth.sendPasswordResetEmail(this.email)
          .then(() => {
            // console.log('A reset email has been sent');
            this.emailSubmitted = true;
            this.$store.commit('setLoadScreen', { status: false });
          })
          .catch(() => {
          });
      }
    },
    checkMethod() {
      this.submitted = true;
      this.googleUser = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const auth = firebase.auth();
        auth.fetchSignInMethodsForEmail(this.email)
          .then((result) => {
            if (result[0] === 'google.com') {
              this.googleUser = true;
              this.$store.commit('setLoadScreen', { status: false });
              // console.log('here');
            } else {
              this.submitEmail();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>

<style scoped>
  .error {
  color: red;
}

.button-color{
  background-color: #BB4B25 !important;
  color: #FFF !important;
}

</style>
