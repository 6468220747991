<template>
  <div id="app">
    <loading :active.sync="loadScreen"
    :is-full-page="true"></loading>
    <router-view/>
    <div class="position-fixed bottom-0 start-0 p-3" style="z-index: 5">
      <div class="error-toast align-items-center text-white bg-danger border-0" role="alert"
      aria-live="assertive" aria-atomic="true" id="errorToast" v-show="error">
        <div class="d-flex">
          <div class="toast-body">
            Oops! Something went wrong. <br />
            Please try again later or
            <a
            href="https://waffle-school.atlassian.net/servicedesk/customer/portals"
            target="_blank">
             contact support
            </a>.
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto"
          @click="dismissError()" aria-label="Close"></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  // data() {
  //   return {
  //     error: false,
  //   };
  // },\
  components: {
    Loading,
  },
  computed: {
    error() {
      return this.$store.state.error;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  methods: {
    dismissError() {
      this.$store.commit('setError', { status: false });
    },
  },
  mounted() {
    // this.errToast = new Toast(document.getElementById('errorToast'));
    this.$store.commit('setError', { status: false });
  },
  errorCaptured(err) {
    // this.errToast.show();
    this.$store.commit('setLoadScreen', { status: false });
    this.$store.commit('setError', { status: true });
    console.log(err);
  },
};
</script>

<style>
@import '~material-icons/iconfont/material-icons.css';
@import '~mathlive/dist/mathlive-fonts.css';
#app {
  /*font-family: Avenir, Helvetica, Arial, sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;

}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.bg-overlay {
  background-image: url(https://waffle.school/wp-content/uploads/2021/05/CTA-Shapes.png);
  background-position: bottom right;
  background-repeat: no-repeat;
  opacity: 1;
  transition: background .3s,border-radius .3s,opacity .3s;
  min-height: 100vh;
}
.md-24 {
  font-size: 24px !important;
  vertical-align: middle;

}
.bg-pri {
  background-color: #BB4B25 !important;
  color: #FFF !important;
}

.bg-sec {
  background-color: #FFE1C2 !important;
  color: #BB4B25 !important;
}

.bg-ter {
  background-color: #FF6531 !important;
  color: #FFF !important;
}

.bg-acc1 {
  background-color: #6EC1E4 !important;
  color: #FFF !important;
}

.bg-acc2 {
  background-color: #54595F !important;
  color: #FFF !important;
}
.input-container{
  /*display: inline-block;*/
  text-align: center;
}
.input-field {
  width: 100%;
  min-height: 1em;
  border: none;
  /*outline: none;*/
  background-color: rgba(187,75,37,0.1);
  border-radius: 3em;
  padding: 0.1em 1em;
}
.input-border {
  margin-left: auto;
  margin-right: auto;
  display:inline-block;
  width: 0px;
  height: 0px;
  background: #87ceeb;
  position: relative;
  top:-2px;
  -webkit-transition: all ease-in-out .15s;
  -o-transition: all ease-in-out .15s;
  transition: all ease-in-out .15s;
}
.input-field:focus {
  outline:  none;
  background-color: rgba(135,206,235,.15);
}

.input-field:focus ~ .input-border {
  height:  2px;
  width: calc(100% - 2em);
}

.error-toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgb(0 0 0 / 15%);
  border-radius: 0.25rem;
}
</style>
