<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>

export default {
  name: 'TakeLanding',
};
</script>
<style scoped>
</style>
