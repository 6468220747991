<template>
  <div id="assconfig" class="container-fluid py-5 col-8 mx-auto">
    <nav class="navbar bg-white fixed-top flex-md-nowrap p-0 navbar-expand-md">
      <button class = "navbar-toggler" type="button" data-bs-toggle="collapse"
      data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class = "row ms-0 py-2 collapse navbar-collapse justify-content-between"
      id="collapsibleNavbar">
        <i class="col-sm-1 text-center pr-0 text-primary bg-white material-icons md-40">
        assignment</i>
        <div class="col-sm-6 pl-0">
          <h4 class="my-auto text-secondary d-inline">
            {{aTitle}}
          </h4>
          <small> - {{aType}}</small>
        </div>
        <div class=" ms-auto col-sm-3 text-center">
            <button class="me-1 btn btn-sm btn-danger" @click="closePage"> Close </button>
        </div>
      </div>
    </nav>
    <div v-for='pDict,s in sDict' @mouseover="activeS=s" :key="s">
      <div v-for="p,q in pDict" class="p-cards" :key="q"
        @click="editMode('sec'+s+'prob'+q)">
        <review-template :ref="'sec'+s+'prob'+q" :q-info="p" :q-no="q"
        :s-no="s" :s-len="sDict.length"
        :s-resp='resp[s][q]'>
        </review-template>
      </div>
      {{updateMath()}}
    </div>
    <div id="zoommodal" class="modal fade justify-content-center" role="dialog">
        <div id="zoom-dialog" class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content"
          style="background-color: transparent!important; border: none!important;">
            <div class="modal-header" >
              <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body p-0 bg-white">
              <img id="zoomimg" src="">
            </div>
          </div>
        </div>
      </div>

    <div class="points-bar col-md-2 bg-white rounded p-2">
      <p class="text-muted my-auto"> Total Points </p>
      <p class="h5 text-center my-auto" style="color: #bb4b25;">
        {{recPoints}} / {{totPoints}}
      </p>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { Modal } from 'bootstrap';
import ReviewTemplate from '../components/ReviewProblem.vue';

export default {
  name: 'ReviewAss',
  components: {
    ReviewTemplate,
  },
  computed: {
    guestInfo() {
      return this.$store.state.guestData;
    },
    respInfo() {
      return this.$store.state.respInfo;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      errorForbidden: false,
      aId: null,
      sDict: [],
      resp: [],
      aTitle: null,
      aType: null,
      recPoints: 0,
      totPoints: 0,
      activeS: 0,
    };
  },
  methods: {
    editMode(elem) {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          this.$refs[key][0].setInactive();
        }
      });
      this.$refs[elem][0].setActive();
    },

    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/assessment/getassessment', {
        aId: this.aId,
      })
        .then((response) => {
          const aTemp = JSON.parse(response.data.assignment);
          this.aTitle = aTemp.title;
          this.aType = aTemp.aType.type;
          this.totPoints = aTemp.totPoints;

          try {
            this.sDict = JSON.parse(aTemp.problems);
            this.getResponseObj();
          } catch (err) {
            console.log(err);
          }
          this.updateMath();
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.$store.commit('setLoadScreen', { status: false });
            this.$router.replace({ path: '/forbidden/' });
          }
        });
    },
    getResponseObj() {
      axios.post('/api/assessment/getresponseobj', {
        respId: this.respInfo._id.$oid, // eslint-disable-line no-underscore-dangle
      })
        .then((response) => {
          try {
            const tempResponse = JSON.parse(response.data.responseObj);
            this.resp = tempResponse.response;
            this.recPoints = tempResponse.points;
            this.$store.commit('setLoadScreen', { status: false });
          } catch (err) {
            console.log(err);
            this.$store.commit('setLoadScreen', { status: false });
          }
        });
    },
    updateMath() {
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 100);
    },

    closePage() {
      this.$router.go(-1);
    },

    imgDetect(event) {
      if (event.target.nodeName === 'IMG') {
        const zoomModal = new Modal(document.getElementById('zoommodal'), {});
        zoomModal.show();
        document.getElementById('zoomimg').src = event.target.src;
      }
    },
  },

  mounted() {
    document.body.addEventListener('dblclick', this.imgDetect);
    this.aId = this.$route.params.id;
    this.getAss();
  },
};
</script>

<style>
</style>
