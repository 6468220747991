<template>
  <div class="row g-0 p-0 min-vh-100">
    <div class="col-lg-4 justify-content-end d-flex bg-rust-gradient">
      <img src="~@/assets/waffle_flakes.png" class="bg-image">
      <div class="divider-register divider-vertical d-none d-lg-block">
        <svg width='100%' height="100%" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" preserveAspectRatio="none">
          <path d="M50,0 L100,0 L100,100 L50,100 C83.333333,83.3333333 100,66.6666667
          100,50 C100,33.333333 83.33333333,16.6666667 50,0 Z" fill="#F3ECe6">
          </path>
        </svg>
      </div>
    </div>
    <div class="col d-flex register-col p-4">
      <div class="container-fluid my-auto register-card text-center">
        <h3 class="text">Create Account</h3>
        <hr>
        <p class="fs-6 text-start my-0"> Step 1: Select a Waffle Assessments Plan </p>
        <div class="row justify-content-center">
          <div class="card card-body h-100 align-item-stretch col-sm-5 mx-2 my-2 p-0"
          style="border-radius:2em;">
            <label>
              <input type="radio" name="product" class="card-select"
              v-model="selectTier" value = "{'id':'1', 'type':'basic'}"/>
              <div class="card-body card-input align-middle">
                <div class="align-middle">
                  <h4 class="card-title fw-bold">Basic</h4>
                  <div class="card-text fs-5 fw-light">
                    Limited features
                  </div>
                  <div class="card-text fs-5 fw-bold">
                    FREE
                  </div>
                </div>
              </div>
            </label>
          </div>
          <div class="card card-body h-100 col-sm-5 mx-2 my-2 p-0" style="border-radius:2em;">
            <label>
              <input type="radio" name="product" class="card-select"
              v-model="selectTier" value="{'id':'2', 'type':'premium'}"/>
              <div class="card-body card-input">
                <h4 class="card-title fw-bold">Premium</h4>
                <div class="card-text fs-5 fw-light">
                  Full features
                </div>
                <div class="card-text fs-6 fw-light text-decoration-line-through">
                  $3/month
                </div>
                <div class="card-text fs-5 fw-bold">
                  $0/month*
                </div>
              </div>
            </label>
          </div>
        </div>
        <div>
          <a class="text-center text-primary fw-light form-text" href="https://waffle.school/products/"
          target="_blank">
            Check out how these plans are different
          </a>
        </div>
        <p class="p-0 m-0 text-start" style="font-size:0.4em">
        Limited time offer* - Sign up before July 31 2021 to get 6 months free
        subscription to Waffle Assessments Premium.
        </p>
        <p class="p-0 m-0 text-start" style="font-size:0.4em">
        Premium subscriptions will be downgraded to our free basic subscription
        at the end of the 6 months.
        <p class="p-0 m-0 text-start" style="font-size:0.4em">
         Users will be provided the choice of payment
         should they choose to continue with the premium subcription.
        </p>
        <div class="row" :class="{ 'is-invalid': googleSubmitted && $v.selectTier.$error }">
          <div v-if="googleSubmitted && !$v.selectTier.required"
          class="error">Please select a plan before signing in with Google</div>
        </div>
        <div class="row" :class="{ 'is-invalid': submitted && $v.selectTier.$error }">
          <div v-if="submitted && !$v.selectTier.required"
          class="error">Please select a plan</div>
        </div>
        <!-- <div class="row" :class="{ 'is-invalid': submitted && $v.toggle.$error }">
          <div v-if="submitted && !$v.toggle.sameAs"
          class="error">Please check the box to continue</div>
        </div>
        <div class="row" :class="{ 'is-invalid': googleSubmitted && $v.toggle.$error }">
          <div v-if="googleSubmitted && !$v.toggle.sameAs"
          class="error">Please check the box to continue</div>
        </div> -->
        <hr>
        <p class="fs-6 text-start my-3"> Step 2: Registration</p>
        <form @submit="submitForm" @submit.prevent>
          <div class="text-start text-secondary mb-3 row input-container">
  <!--             <label class="form-label fs-5">First Name</label> -->
            <input type="text" name="firstname"
            class=" fs-6 input-field" v-model="user.firstName"
            placeholder="First Name"
            :class="{ 'is-invalid': submitted && $v.user.firstName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.firstName.required"
            class="form-text error">First Name is required</div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <!-- <label class="form-label fs-5" >Last Name</label> -->
            <input type="text" name="lastname"
            class=" fs-6 input-field" v-model="user.lastName" placeholder="Last Name"
            :class="{ 'is-invalid': submitted && $v.user.lastName.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.lastName.required"
            class="form-text error">Last Name is required</div>
          </div>
          <div id="email-field" class="text-start text-secondary mb-3 row input-container">
<!--             <label class="form-label fs-5">Email</label> -->
            <input @blur="checkUser" @focus="emailEntry=false" type="text" name="email"
            placeholder="Email" class=" fs-6 input-field" v-model="user.userEmail"
            :class="{ 'is-invalid': submitted && $v.user.userEmail.$error,
            'user-valid': userValid && emailEntry && !$v.user.userEmail.$error,
            'user-invalid': emailEntry && !$v.user.userEmail.$error }">
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.userEmail.required" class="form-text error">
              Email is required</div>
            <div v-if="submitted && !$v.user.userEmail.email" class="form-text error">
            Email is invalid</div>
            <div v-show="emailEntry && !userValid && !$v.user.userEmail.$error"
            class="error">
              <small>
                This email is already been registered, please sign in
                <router-link class= "text-danger text-underline" to="/login">here</router-link>
              </small>
            </div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <!-- <label class="form-label fs-5">Create Password</label> -->
            <input type="password" name="password" placeholder="Password"
            class=" fs-6 input-field" v-model="user.password"
            :class="{ 'is-invalid': submitted && $v.user.password.$error }"/>
            <span class="input-border"></span>
<!--             <div class="form-text">
              <p>Password must be at least 8 characters long.
              Password must contain Uppercase and lowercase characters, numbers and symbols</p>
            </div> -->
            <div v-if="submitted && !$v.user.password.required" class="form-text error">
            Password is required</div>
            <div v-if="submitted && !$v.user.password.minLength" class="form-text error">
            Password must be at least 8 characters long</div>
            <div v-if="submitted && !$v.user.password.valid && user.password"
            class="form-text error">
            Password must contain Uppercase and lowercase characters, numbers and symbols</div>
          </div>
          <div class="text-start text-secondary mb-3 row input-container">
            <!-- <label class="form-label fs-5" >Confirm Password</label> -->
            <input type="password" name="confirmpassword" placeholder="Confirm Password"
            class=" fs-6 input-field" v-model="user.confirmPassword"
            :class="{ 'is-invalid': submitted && $v.user.confirmPassword.$error }"/>
            <span class="input-border"></span>
            <div v-if="submitted && !$v.user.confirmPassword.required"
            class="form-text error">Confirm Password is required</div>
            <div v-if="submitted && !$v.user.confirmPassword.sameAsPassword"
            class="form-text error">Passwords do not match</div>
          </div>
     <!--      <div class="form-check form-text text-start">
            By signing up, you agree to our <a class="text-start text-primary fw-light form-text"
            id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
          </div> -->
         <!--  <div class="bg-gradient-primary float-end">
            <button type="submit" class="btn bg-pri mt-2 mx-2"
            @click="submitForm">Sign Up
            </button>
          </div>
          <div class="form-check form-text text-start">
            By signing up, you agree to our <a class="text-start text-primary fw-light form-text"
            id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
          </div>
          <div>
            <button @click="checkGoogleUser" class="social-button">
              <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
            </button>
          </div> -->
          <div class="row">
            <div class="col text-start">
              <p class="mb-0 form-text">Already have an account?</p>
              <router-link to="/login" class="float-start my-0 text-primary fw-light form-text">
               Log In</router-link>
            </div>
            <div class="col bg-gradient-primary text-white">
              <button type="submit"
              class=" float-end btn bg-pri my-2 text-center" @click="submitForm">
                Sign Up
              </button>
            </div>
          </div>
        </form>
        <p class="fs-5"> or
          <button @click="checkGoogleUser" class="social-button mt-2">
            <img alt="Google Logo" src="../assets/btn_google_signin_light_normal_web.png">
          </button>
        </p>
        <div class="form-check form-text text-center">
          By signing up, you agree to our <a class="text-primary fw-light form-text"
          id="a" href="http://waffle.school/terms-conditions">T&C and privacy policy</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import firebase from 'firebase';
import {
  required, email, minLength, sameAs,
} from 'vuelidate/lib/validators';
// import firebase from 'firebase';

export default {
  name: 'Register',
  components: {
  },
  data() {
    return {
      user: {
        firstName: '',
        lastName: '',
        userEmail: '',
        password: '',
        confirmPassword: '',
      },
      submitted: false,
      googleSubmitted: false,
      invalid: null,
      userValid: null,
      emailEntry: false,
      selectTier: '{\'id\':\'2\', \'type\':\'premium\'}',
      userData: {},
      // toggle: false,
    };
  },
  validations: {
    user: {
      firstName: { required },
      lastName: { required },
      userEmail: { required, email },
      password: {
        required,
        minLength: minLength(8), // I assume you'd want something like this too
        valid(value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase
            && containsLowercase
            && containsNumber
            && containsSpecial
          );
        },
      },
      confirmPassword: { required, sameAsPassword: sameAs('password') },
    },
    // toggle: {
    //   sameAs: sameAs(() => true),
    // },
    selectTier: { required },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      if (this.userValid) {
        this.$v.$touch();
        if (!this.$v.user.$invalid && !this.$v.selectTier.$invalid
          && this.userValid) {
          this.$store.commit('setLoadScreen', { status: true });
          const signDate = new Date();
          const payload = {
            name: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.userEmail,
            password: this.user.password,
            tier: this.selectTier,
            signUpDate: signDate,
            lastLogin: signDate,
          };
          this.$store.dispatch('userRegister', payload)
            .then(() => {
              this.$router.replace('/login');
              this.$store.commit('setLoadScreen', { status: false });
            })
            .catch(() => {
              this.$store.commit('setLoadScreen', { status: false });
            });
        }
      }
    },
    checkGoogleUser() {
      this.googleSubmitted = true;
      // this.$v.$touch();
      if (!this.$v.selectTier.$invalid) {
        const provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope('profile');
        provider.addScope('email');
        firebase.auth().signInWithPopup(provider)
          .then((result) => {
            // const token = result.credential.accessToken;
            const { user } = result;
            const signDate = new Date();
            // console.log(user, token);
            this.userData = {
              name: user.displayName,
              email: user.email,
              tier: this.selectTier,
              fId: user.uid,
              signUpDate: signDate,
              lastLogin: signDate,
            };
            axios.post('/api/checkUser', { email: user.email })
              .then((res) => {
                if (res.data.valid === 1) {
                  this.googleRegister();
                } else {
                  this.googleLogin();
                }
              });
          });
      }
    },
    googleRegister() {
      this.$store.dispatch('googleRegister', this.userData)
        .then(() => {
          this.$router.replace('/');
        }).catch(() => {
          this.invalid = false;
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    googleLogin() {
      this.$store.dispatch('googleLogin', this.userData)
        .then(() => {
          this.$router.replace('/');
        }).catch(() => {
          this.invalid = false;
          this.$store.commit('setLoadScreen', { status: false });
        });
    },
    checkUser() {
      try {
        this.$v.user.userEmail.$touch();
        const payload = {
          email: this.user.userEmail,
        };

        axios.post('/api/checkUser', payload)
          .then((res) => {
            this.emailEntry = true;
            if (res.data.valid === 1) {
              this.userValid = true;
            } else {
              this.userValid = false;
            }
          });
      } catch (error) {
        console.error(error.response);
      }
    },
  },
  mounted() {
    // this.gettiers();
  },
};

</script>

<style scoped>
.error {
  color: red;
  font-size: 0.8em;
}

#email-field i {
  position: absolute;
  top: 270px;
  right: 30px;
  /*min-width: 40px;*/
}
.user-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/error_red_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.user-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("~@/assets/check_circle_green_24dp.svg");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

label {
  width: 100%;
}

.card-select {
  display: none;
}

.card-input {
  margin: 00px;
  /*height: 100%;*/
  height: 10em;
  width: 100%;
  border-radius: 2em;
}

.card-input:hover {
  cursor: pointer;
  box-shadow: .25rem .25rem rgba(20,1,1,0.075);
}

.card-select:checked + .card-input {
  background-color: #E97852;
  box-shadow: .125rem .25rem rgba(0,0,0,0.075);
  color: #FFF;
 }

.divider-register {
  position: relative;
  z-index: 0; }
  .divider-register svg {
    background: none !important;
    height: 6vw; }
    .divider-register svg:not([class*='bg']) path {
      background-color: #f3ece6; }
  .divider-register:not(:first-child) {
    top: 1px; }
  .divider-register:first-child {
    bottom: 1px; }
  .divider-register.divider-vertical {
    height: 100%;
    width: 25rem;
    top: 0;
    left: 1px; }
    .divider-register.divider-vertical svg {
      height: 100%;
      width: 25rem;
    }

.bg-image {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0; }
  .bg-image:not([class*='absolute']) {
    position: absolute; }
  .bg-image + .card-body {
    position: relative;
    z-index: 0;
}

img.bg-image {
  width: 50%;
  object-fit: cover;
  vertical-align: middle;
    border-style: none; }

.register-col {
  z-index: 1;
  position: relative;
  object-fit: cover;
  background-color: #f3ece6;
  min-height: 100vh;
  background-size: auto;
  background-repeat: repeat;
}

.register-card {
  max-width: 50%;
}

.bg-rust-gradient {
  /*background: rgb(187,75,37);*/
  background: linear-gradient(159deg, rgba(187,75,37,1) 0%, rgba(233,120,82,1) 50%);
}

.social-button img {
  width: 100%;
  background-color: #f3ece6;
}

.social-button {
  border: 0;
}
</style>
