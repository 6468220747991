<template>
  <div class="bg-overlay">
    <Navbar></Navbar>
    <div class="col-md-7 col-lg-8 mx-auto pt-5">
      <div class="modal fade" id="confirmEdit"
        tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Edit Info</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body fs-5">
              Please confirm to apply the changes.
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-acc2"
              data-bs-dismiss="modal">Cancel</button>
              <button type="button" class="btn bg-pri"
              v-on:click="updateInfo">Confirm</button>
            </div>
          </div>
        </div>
      </div>
      <div class="card card-shadow pt-2 mt-4 left-accent-card">
        <div class="card-body">
          <h3 class="card-title d-inline mb-4">Account Information</h3>
          <div
          class="text-white d-inline">
            <button v-show="googleUser===false && edit===false" type="button"
            class="float-end btn bg-pri text-center" @click="editInfo">
            <span class="material-icons-outlined align-middle"
               data-bs-toggle="tooltip" title="Edit">edit</span>
              Edit
            </button>
          </div>
          <form @submit="updateInfo" class="container">
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-5">Given Name</label>
              <input type="text" name="name" :disabled="edit == false"
              class="input-field" v-model="firstName"
              :class="{ 'edit-mode': edit,
              'is-invalid': submitted && $v.firstName.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.firstName.required"
              class="form-text error">Given Name is required</div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-5">Last Name</label>
              <input type="text" name="name"
              class="input-field" v-model="lastName" :disabled="edit == false"
              :class="{ 'edit-mode': edit,
              'is-invalid': submitted && $v.lastName.$error }"/>
              <span class="input-border"></span>
              <div v-if="submitted && !$v.lastName.required"
              class="form-text error">Last Name is required</div>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-5">Email</label>
              <input type="text" name="email"
              class="input-field" v-model="email" disabled>
              <span class="input-border"></span>
            </div>
            <div class="form-group d-flex fs-4 text-start mt-4 row px-3 input-container">
              <label class="fs-5">Subscription Plan</label>
              <input type="text" name="tier"
              class="input-field" v-model="tier.type" disabled>
              <span class="input-border"></span>
            </div>
            <div class="form-group fs-5 text-start mt-4 g-2 row input-container">
              <div v-show="googleUser===false" class="col-auto">
                <button type="submit" class="btn mt-0 btn bg-acc1"
                @click="$router.push('/updatepassword')">Change Password</button>
              </div>
            </div>
            <div v-show="googleUser===false && edit===true"
            class="col mx-2 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-pri text-center" @click="loadEdit">
                Update
              </button>
            </div>
            <div v-show="googleUser===false && edit===true"
            class="col mx-2 bg-gradient-primary text-white">
              <button type="button"
              class=" mx-2 float-end btn bg-ter text-center" @click="cancelEdit">
                Cancel
              </button>
            </div>
            <!-- <div>
              <button type="button"
              class="btn bg-pri btn-lg text-center">
                Primary
              </button>
            </div>
            <div>
              <button type="button"
              class="btn bg-sec btn-lg text-center">
                Secondary
              </button>
            </div>
            <div>
              <button type="button"
              class="btn bg-ter btn-lg text-center">
                Tertiary
              </button>
            </div>
            <div>
              <button type="button"
              class="btn bg-acc1 btn-lg text-center">
                Accent 1
              </button>
            </div>
            <div>
              <button type="button"
              class="btn bg-acc2 btn-lg text-center">
                Accent 2
              </button>
            </div> -->
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import firebase from 'firebase';
import { Modal } from 'bootstrap';
import {
  required,
} from 'vuelidate/lib/validators';
import Navbar from '../components/Navbar.vue';

export default {
  name: 'Account',
  components: {
    Navbar,
  },
  computed: {
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      changesMade: false,
      editingPassword: false,
      passwords: {
        current: '',
        new: '',
        newConfirm: '',
      },
      passwordFail: false,
      verifyFail: false,
      firstName: '',
      lastName: '',
      email: '',
      method: '',
      fId: '',
      googleUser: false,
      tier: {},
      edit: false,
      submitted: false,
      invalid: null,
    };
  },
  validations: {
    firstName: { required },
    lastName: { required },
  },
  methods: {
    getUserData() {
      this.$store.commit('setLoadScreen', { status: true });
      const { userData } = this.$store.state;
      // console.log(userData);
      let fullName = '';
      fullName = userData.name;
      const tempName = fullName.split(' ');
      this.lastName = tempName.slice(-1);
      this.firstName = tempName.slice(0, -1).join(' ');
      this.email = userData.email;
      // console.log(userData.tier);
      this.tier = JSON.parse(userData.tier.replace(/'/g, '"'));
      this.fId = userData.fId;
      const auth = firebase.auth();
      auth.fetchSignInMethodsForEmail(this.email)
        .then((result) => {
          if (result[0] === 'google.com') {
            this.method = 'Google Account';
            this.googleUser = true;
          } else {
            this.method = 'Password';
          }
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    loadEdit() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.editModal = new Modal(document.getElementById('confirmEdit', {}));
        this.editModal.show();
      }
    },
    editInfo() {
      this.edit = true;
    },
    cancelEdit() {
      this.edit = false;
      this.getUserData();
    },
    updateInfo() {
      this.submitted = true;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$store.commit('setLoadScreen', { status: true });
        const payload = {
          name: `${this.firstName} ${this.lastName}`,
          fId: this.fId,
        };
        axios.post('/api/updateUser', payload)
          .then(() => {
            const userData = {
              name: `${this.firstName} ${this.lastName}`,
              fId: this.fId,
              email: this.email,
              tier: JSON.stringify(this.tier),
            };
            this.$store.commit('updateUserData', userData);
          }).catch((err) => {
            console.error(err);
            this.getUserData();
            this.$store.commit('setLoadScreen', { status: false });
          });
        this.edit = false;
        this.submitted = false;
        this.editModal.hide();
        this.$store.commit('setLoadScreen', { status: false });
      }
    },
  },
  mounted() {
    this.getUserData();
  },
};
</script>

<style>
.error {
  color: red;
}
input.edit-mode {
  background: #F3ECE6 !important;
  color: #000 !important;
}
</style>
