<template>
  <!-- Free Response -->
  <div id="fr-cont" v-if="qInfo['type']==0">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <div class="mt-4 container border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax"><em v-html="sResp['answer']"></em></p>
        </div>

        <p class=" px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>
        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>

        <div class="row ms-0">
          <p class="text-primary text-small col-sm-5 e_btn px-0 mt-4" @click="activeAkey"><i
            class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
          <div class="col-sm-7 mt-4 mx-0 text-end">
            <p class="mb-0 text-success">
              <strong>
              {{sResp['awarded']}}/{{qInfo['points']}}
              </strong> points </p>
          </div>
        </div>

      </div>
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']"><em>
        "Problem Statement"</em> </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <p class="mt-2">
        Enter key words and/or phrases. Each key word must be on a new line.
        </p>
        <p class="my-4 mathjax p-pre d-inline"><em v-html="qInfo['solution']"></em></p>
        <div class="row ms-0">
          <div class="col-sm-7 my-auto px-0 text-start">
            <p class="my-auto mx-0 text-success">
              <strong>
              {{sResp['awarded']}}/{{qInfo['points']}}
              </strong> points </p>
          </div>
          <div class="col-sm-5 text-end align-middle">
            <button type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
            @click="deactiveAkey">Done</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 p-4" v-if="qInfo['type']==0">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Free Response </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4"
        v-if="qInfo['problem'].length==0 && !qInfo['image']">Enter Problem Statement </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <div class="mt-4 container border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax"><em v-html="sResp['answer']"></em></p>
        </div>

        <p class="px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>
        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>

        <div class="row ms-0">
          <div class="col-sm-12 mt-4 mx-0 text-end">
            <p class="mb-0 text-success"><strong>
            {{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Multiple Choice Questions -->
  <div id="mc-cont" v-else-if="qInfo['type']==1">
    <div v-if="active">
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">
        Multiple Choice </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em>"Problem Statement"</em> </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>

        <p> Select the correct answer choice: </p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="ind">
          <input class="form-check-input" type="radio" id:="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="sResp['answer']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]">
              <strong v-if="qInfo['solution']===o && sResp['answer']===o"
              class="text-success">Correct! </strong>
              <strong v-else-if="qInfo['solution']===o && !correct"
              class="text-success"> Correct Answer </strong>
              <strong v-else-if="sResp['answer']===o && !correct"
              class="text-warning "> Your Answer </strong>
            </em>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>

        <p class=" px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>

        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>
        <div class="row ms-0">
          <p class="text-primary text-small col-sm-5 e_btn px-0 mt-4" @click="activeAkey"><i
            class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
          <div class="col-sm-7 mt-4 mx-0 text-end">
            <p class="mb-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
        </div>

      </div>
      <div class="mc-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1">
          <small class="bg-light text-muted px-1">Multiple Choice</small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em>"Problem Statement"</em> </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>

        <p> Select the correct answer choice: </p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="ind">
          <input class="form-check-input" type="radio" id:="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="qInfo['solution']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0"><em
            v-html="qInfo['options'][ind]"></em></p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>
        <div class="row ms-0">
          <div class="col-sm-7 my-auto px-0 text-start">
            <p class="my-auto mx-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
          <div class="col-sm-5 text-end align-middle">
            <button type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
            @click="deactiveAkey">Done</button>
          </div>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="mc-response q-field col-8 mx-auto my-2 p-4">
        <div class=" mb-1">
          <small class="bg-light text-muted px-1">Multiple Choice</small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <div class="form-check my-2" v-for="o,ind in qInfo['options']" :key="ind">
          <input class="form-check-input" type="radio" id:="ind" :name="'answer'+qNo"
          v-bind:value="o" v-model="sResp['answer']" disabled>
          <p class="my-4 mathjax p-pre d-inline" v-if="o.length>0">
            <em v-html="qInfo['options'][ind]">
              <strong v-if="qInfo['solution']===o && sResp['answer']===o"
              class="text-success "> Correct! </strong>
              <strong v-else-if="qInfo['solution']===o && !correct"
              class="text-success "> Correct Answer </strong>
              <strong v-else-if="sResp['answer']===o && !correct"
              class="text-warning "> Your Answer </strong>
            </em>
          </p>
          <label for="ind" v-else> Enter answer choice </label>
        </div>

        <p class="px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>

        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>
        <div class="row ms-0">
          <div class="col-sm-12 mt-4 mx-0 text-end">
            <p class="mb-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
        </div>

      </div>
    </div>
  </div>

  <!-- Section Header -->
  <div id="fr-cont" v-else-if="qInfo['type']==2">
    <div>
      <div class="divider"> Section {{sNo+1}}/{{sLen}}</div>
      <div class="s-header q-field col-8 mx-auto mb-2 px-4 pb-4">
        <div class="row ms-0 mt-4">
          <h4 class="px-0 py-1 t-fr col-sm-8" v-if="qInfo['title']" v-html="qInfo['title']">
          </h4>
          <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
            <em> Section Title </em>
          </h4>
        </div>

        <div data-ph="Description" class="px-0 py-1 col-sm-8"
        v-html="qInfo['desc']" v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>

      </div>
    </div>
  </div>

  <!-- Title/Instructions -->
  <div id="fr-cont" v-else-if="qInfo['type']==3">
    <div>
      <div class="f-response q-field col-8 mx-auto mb-2 px-4 pb-4">
        <div class="row ms-0 mt-4">
          <h4 class="px-0 py-1 col-sm-8" v-if="qInfo['title']">
            {{qInfo['title']}}
          </h4>
          <h4 class="px-0 py-1 text-muted col-sm-8 h4" v-else >
            <em> Title </em>
          </h4>
        </div>

        <div data-ph="Description" class="px-0 py-1 col-sm-8"
        v-html="qInfo['desc']" v-if="qInfo['desc']">
        </div>
        <p data-ph="Description" class="px-0 py-1 col-sm-8 text-muted" v-else>
          <em>Description</em>
        </p>

      </div>
    </div>
  </div>

  <!-- Short Answer -->
  <div id="fr-cont" v-else-if="qInfo['type']==4">
    <div v-if="active">
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-if="!aKey">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer</small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <div class="mt-4 container border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax text-center"><em v-html="sResp['answer']"></em></p>
        </div>

        <p class=" px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>
        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>

        <div class="row ms-0">
          <p class="text-primary text-small col-sm-5 e_btn px-0 mt-4" @click="activeAkey"><i
            class="material-icons align-middle">assignment_turned_in</i> Answer Key</p>
          <div class="col-sm-7 mt-4 mx-0 text-end">
            <p class="mb-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
        </div>

      </div>
      <div class="f-response q-field col-8 mx-auto my-2 p-4 selected-qn" v-else>
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
          <em>"Problem Statement"</em> </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <p class="mt-2"> Acceptable answers separated by commas. </p>
        <p class="my-4 mathjax p-pre d-inline"><strong v-html="qInfo['solution']"></strong></p>
        <div class="row mt-4 ms-0">
          <div class="col-sm-7 my-auto px-0 text-start">
            <p class="my-auto mx-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
          <div class="col-sm-5 text-end align-middle">
            <button type="button" class="mb-0 btn btn-outline-primary btn-small float-end"
            @click="deactiveAkey">Done</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="f-response q-field col-8 mx-auto my-2 p-4">
        <div class=" mb-1"> <small class="bg-light text-muted px-1">Short Answer </small></div>
        <h5 class="mt-2 q-no" v-if="qInfo['title']"> {{qInfo['title']}}</h5>
        <h5 class="mt-2 q-no" v-else> Question {{qNo}} </h5>
        <div v-show="qInfo['image']" class="mt-4 mb-2">
          <img v-bind:src="qInfo['image']" class="d-flex img-pre mx-auto" />
        </div>
        <p class="my-4" v-if="qInfo['problem'].length==0 && !qInfo['image']">
        Enter Problem Statement </p>
        <p class="my-4 mathjax p-pre" v-else><em v-html="qInfo['problem']"></em></p>
        <div class="mt-4 container border rounded p-1 pt-2">
          <p class="ms-2 pre-title text-muted bg-white"> answer </p>
          <div v-show="sResp['a-image']" class="mt-4 mb-2">
            <img v-bind:src="sResp['a-image']" class="d-flex img-pre mx-auto" />
          </div>
          <p class="mathjax text-center"><em v-html="sResp['answer']"></em></p>
        </div>

        <p class="px-0 mb-0 mt-4" v-show="sResp['feedback'].length>0">Feedback &nbsp;
          <i class="material-icons text-success e_btn align-middle">feedback</i>
        </p>
        <div class="mb-4 t-fr bg-light p-2" v-show="sResp['feedback'].length>0">
        {{sResp['feedback']}}</div>

        <div class="row ms-0">
          <div class="col-sm-12 mt-4 mx-0 text-end">
            <p class="mb-0 text-success">
              <strong>{{sResp['awarded']}}/{{qInfo['points']}}</strong> points </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'review-template',
  props: ['qInfo', 'qNo', 'sNo', 'sLen', 'sResp'],
  data() {
    return {
      active: false,
      aKey: false,
      editor: null,
      activeSct: false,
      previewImage: null,
      pContent: null,
      filename: '',
      feedback: false,
      correct: false,
    };
  },
  methods: {
    setActive() {
      this.active = true;
      this.reRender();
      // CKEDITOR.replace('problemEdit');
    },
    setInactive() {
      this.active = false;
      this.reRender();
    },
    activeAkey() {
      this.aKey = true;
      this.reRender();
    },
    deactiveAkey() {
      this.aKey = false;
      this.reRender();
    },
    reRender() {
      // this.$parent.updateMath();
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },
  },

  mounted() {
    if (this.sResp.answer === this.qInfo.solution) {
      this.correct = true;
    }

    // console.log(this.sResp);
  },
};
</script>

<style></style>
