 <template>
  <div>
    <div id="assconfig" class="container-fluid py-5 col-8 mx-auto"
    v-if="valid && started && !excess">
      <nav class="navbar bg-white fixed-top flex-md-nowrap p-0 navbar-expand-md">
        <button class = "navbar-toggler" type="button"
        data-toggle="collapse" data-target="#collapsibleNavbar">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class = "row mx-0 collapse navbar-collapse justify-content-between"
        id="collapsibleNavbar">
          <i class="col-sm-1 text-center text-primary bg-white material-icons md-40">
            assignment
          </i>
          <div class="col-sm-2">
            <h4 class="my-auto text-secondary"> {{aTitle}} </h4>
          </div>
          <div class="row ms-auto col-sm-5 pl-4">
            <div class="col-sm-8">
              <p class="text-muted text-end my-auto mx-0">
                <span class="align-middle text-primary me-1"
                v-if="save=='Saved' || save=='Saving...'">
                  <em> {{save}} </em>
                </span>
                <span class="align-middle text-danger me-1" v-else> <em> {{save}} </em></span>
              </p>
              <p class="text-muted text-end my-auto mx-0">
                <span v-show="aType!=1" class="align-middle"> Attempt: {{aAtt}}/{{aTAtt}} </span>
                <i v-show="this.sActivity" class="material-icons text-danger align-middle">
                  report_problem
                </i>
              </p>
            </div>
            <div class="col-sm-4 my-auto text-center">
              <button class="me-1 text-end btn btn-sm btn-primary"
              @click="saveResponse"> Submit </button>
            </div>
          </div>
        </div>
      </nav>

      <div class="modal fade" id="cheatModal" tabindex="-1"
      aria-labelledby="credentialLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-danger" id="exampleModalLabel">Caution!</h5>
              <button type="button" class="btn-close"
                data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body">
              <p>This is assignment is monitored.</p>
              <p> Please do not leave the assignment screen, exit fullscreen,
              or switch to another application/browser tab. </p>
              <p>Click on submit when you have completed the assignment, the application will exit
              fullscreen and return to the previous page automatically.</p>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn bg-pri btn-sm
                text-white mt-3" data-bs-dismiss="modal"> Proceed </button>
            </div>
          </div>
        </div>
      </div>

      <div v-for="p_dict, s in sDict" :key="s" @mouseover="activeS=s">
        <div v-for="p,q in p_dict" class="p-cards" :key="q" @click="editMode('sec'+s+'prob'+q)">
          <take-problem :ref="'sec'+s+'prob'+q" :q-info="p" :q-no="q"
          :s-no="s" :s-len="sDict.length" :s-resp="resp[s][q]"
          v-if="resp[s]">
          </take-problem>
        </div>
      </div>

      <div id="zoommodal" class="modal fade justify-content-center" role="dialog">
        <div id="zoom-dialog" class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content"
          style="background-color: transparent!important; border: none!important;">
            <div class="modal-header" >
              <button type="button" class="btn-close"
              data-bs-dismiss="modal" aria-label="Close">
              </button>
            </div>
            <div class="modal-body p-0 bg-white">
              <img id="zoomimg" src="">
            </div>
          </div>
        </div>
      </div>

      <div class="points-bar col-md-2 bg-white rounded p-2">
        <p class="text-muted my-auto"> Total Points </p>
        <p class="h5 text-center my-auto" style="color: #bb4b25;">{{totPoints}} </p>
      </div>

      <div class="time-bar col-md-2 bg-white rounded p-2">
        <p class="text-muted my-auto"> Time Remaining </p>
        <p class="h5 text-center my-auto" style="color: #bb4b25;">{{aRemain}} </p>
      </div>
    </div>
  <div class="no-access" v-else>
    <div class="card">
      <div class="card-header" v-if="started && !excess">
        Assignment has expired. Please contact your instructor for help.
        <div class="modal fade show d-block" id="saveModal" tabindex="-1"
        role="dialog" v-show="save=='No internet.'">
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-danger" id="exampleModalLabel">
                  Assignment has ended.
                </h5>
              </div>
              <div class="modal-body">
                <b class="text-danger">
                  Please wait for the dialog box and click OK when the prompt appears.
                </b>
                <p class="text-primary">
                  The page will automatically redirect.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-header" v-else-if="!started">
        The assignment has not started. Please come back to this page at the start time.
      </div>
      <div class="card-header" v-else>
        You have run out of attemps. Please contact your instructor for help.
      </div>
      <div class="card-body">
        <button class="btn float-end btn-primary" @click="goBack"> Go Back </button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import axios from 'axios';
// import $ from 'jquery';
import { Modal } from 'bootstrap';
import TakeProblem from './TakeProblem.vue';

export default {
  name: 'TakeAss',
  props: ['userData'],
  components: {
    // 'access-denied': accessDenied,
    'take-problem': TakeProblem,
  },
  computed: {
    guestInfo() {
      return this.$store.state.guestData;
    },
    respInfo() {
      return this.$store.state.respInfo;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
  },
  data() {
    return {
      errorForbidden: false,
      assID: null,
      sDict: [],
      resp: [],
      aTitle: null,
      aInst: null,
      aTime: null,
      aSched: null,
      aType: null,
      aRemain: 0,
      aAtt: null,
      aTAtt: null,
      totPoints: 0,
      activeS: 0,
      valid: true,
      started: true,
      excess: false,
      sActivity: false,
      antiCheat: false,
      timer: null,
      saveTimer: null,
      save: 'Not Saved',
      flags: { leave: 0, switch: 0 },
    };
  },
  methods: {
    editMode(elem) {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          if (key !== elem && this.$refs[key][0].active) {
            this.$refs[key][0].setInactive();
          }
        }
      });
      if (this.$refs[elem][0].active === false) {
        this.$refs[elem][0].setActive();
      }
    },
    loadCheat() {
      this.deleteModal = new Modal(document.getElementById('cheatModal', {}));
      this.deleteModal.show();
    },
    beginTime() {
      let cTemp = null;
      let fin1 = null;
      let remain1 = null;
      let finD = null;
      let remainD = null;
      this.timer = setInterval(() => {
        // let c_temp = new Date();
        cTemp = new Date();
        cTemp = cTemp.getTime() / 1000;
        if (cTemp > this.aSched) {
          this.started = true;
          switch (this.aType) {
            case 1:
              // let fin_1 = this.aSched + this.aTime*60;
              fin1 = this.aSched + this.aTime * 60;
              if (cTemp > fin1) {
                this.save = 'Saving...';
                this.saveResponse();
                this.valid = false;
              } else {
                remain1 = fin1 - cTemp;
                // let remain_1 = fin_1 - c_temp;

                // console.log(remain_1);
                // this.aRemain = new Date(remain_1 * 1000).toISOString().substr(11,8);
                this.aRemain = `${String(Math.floor(remain1 / 86400))} D 
                ${String(Math.floor((remain1 % 86400) / 3600))}:
                ${String(Math.floor((remain1 % 86400 % 3600) / 60))}:
                ${String(Math.floor(remain1 % 86400 % 3600 % 60))} H`;
              }
              break;
            default:
              finD = this.aSched + this.aTime * 60;
              // let fin_d = this.aSched + this.aTime*60;
              if (cTemp > finD) {
                this.save = 'Saving...';
                this.saveResponse();
                this.valid = false;
              } else {
                remainD = finD - cTemp;
                // let remain_d = fin_d - c_temp;
                // this.aRemain = new Date(remain_d * 1000).toISOString().substr(11,8);
                this.aRemain = `${String(Math.floor((remainD % 86400) / 3600))}h ${String(Math.floor((remainD % 86400 % 3600) / 60))
                }m ${String(Math.floor(remainD % 86400 % 3600 % 60))}s `;
              }
              break;
          }
        } else {
          this.started = false;
        }
      }, 1000);
    },

    saveResponse() {
      this.save = 'Saving...';
      this.resp.forEach((sec, si) => {
        sec.forEach((prob, pi) => {
          if (this.sDict[si][pi].type === 1) {
            this.mcCorrect(si, pi);
          } else if (this.sDict[si][pi].type === 4) {
            this.saCorrect(si, pi);
          }
          this.resp[si][pi].answer = prob.answer.replace(/"/g, '"').replace(/'/g, "'");
        });
      });
      const submitTime = new Date().toISOString();
      axios.post('/api/assessment/updateresponse', {
        email: this.guestInfo.email,
        response: this.resp,
        aId: this.assID,
        flags: this.flags,
        submitted: submitTime,
      })
        .then(() => {
          this.save = 'Saved';
          this.goBack();
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response);
          } else {
            console.log('Please check your internet connection!');
            this.save = 'No internet.';
          }
        });
    },
    mcCorrect(si, pi) {
      if (this.resp[si][pi].answer === this.sDict[si][pi].solution) {
        this.resp[si][pi].awarded = parseInt(this.sDict[si][pi].points, 10);
      }
    },

    saCorrect(si, pi) {
      const sols = this.sDict[si][pi].solution.split(/, |,/g);
      if (sols.includes(this.resp[si][pi].answer) && sols[0].length > 0) {
        this.resp[si][pi].awarded = parseInt(this.sDict[si][pi].points, 10);
      }
    },

    goBack() {
      this.$router.go(-1);
    },

    getAss() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/assessment/getassessment', {
        aId: this.assID,
      })
        .then((response) => {
          this.errorForbidden = false;
          const aTemp = JSON.parse(response.data.assignment);
          this.aTitle = aTemp.title;
          this.aType = aTemp.aType;
          this.aTime = aTemp.totTime;
          this.totPoints = aTemp.totPoints;
          this.aTAtt = aTemp.att;
          this.aSched = new Date(aTemp.schedUTC);
          this.aSched = this.aSched.getTime() / 1000;
          this.antiCheat = !!aTemp.monitor;
          // console.log(this.aTitle, this.aType, this.aTime);

          try {
            this.sDict = JSON.parse(aTemp.problems);
            this.getResponseObj();
          } catch (err) {
            console.log(err);
          }

          if (this.antiCheat) {
            const elem = document.documentElement;
            if (elem.requestFullscreen) {
              elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
              elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
              elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
              elem.msRequestFullscreen();
            }
            this.loadCheat();
            // $('#cheatModal').modal('show');
          }
          this.updateMath();
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((error) => {
          if (error.response.status === 403) {
            this.errorForbidden = true;
            this.$store.commit('setLoadScreen', { status: false });
          }
        });
    },
    getResponseObj() {
      const started = new Date().toISOString();
      // console.log(started);
      axios.post('/api/assessment/getresponseobj', {
        respId: this.respInfo._id.$oid, // eslint-disable-line no-underscore-dangle
        started,
        attempt: 1,
      })
        .then((response) => {
          try {
            const tempResponse = JSON.parse(response.data.responseObj);
            this.resp = tempResponse.response;
            this.aAtt = tempResponse.attempts;
            if (parseInt(this.aType.id, 10) !== 1) {
              this.excess = this.aAtt > this.aTAtt;
            }
            // console.log(this.resp);
            this.beginTime();
            this.$store.commit('setLoadScreen', { status: false });
          } catch (err) {
            console.log(err);
            this.$store.commit('setLoadScreen', { status: false });
          }
        });
    },
    updateMath() {
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 0);
    },

    switchDetect() {
      if (this.antiCheat) {
        this.sActivity = true;
        this.flags.switch += 1;
        this.detectChange();
        // console.log("switch");
      }
    },

    leaveDetect() {
      if (this.antiCheat) {
        this.sActivity = true;
        this.flags.leave += 1;
        this.detectChange();
        // console.log("leave");
      }
    },

    detectChange() {
      if (this.saveTimer) {
        clearTimeout(this.saveTimer);
        this.saveTimer = null;
      }
      this.saveTimer = setTimeout(() => {
        // console.log("saving...");
        this.save = 'Saving...';
        this.resp.forEach((sec, si) => {
          sec.forEach((prob, pi) => {
            this.resp[si][pi].answer = prob.answer.replace(/"/g, '"').replace(/'/g, "'");
          });
        });
        const submitTime = new Date().toISOString();
        axios.post('/api/assessment/updateresponse', {
          email: this.guestInfo.email,
          response: this.resp,
          aId: this.assID,
          flags: this.flags,
          submitted: submitTime,
        })
          .then(() => {
            this.save = 'Saved';
          })
          .catch((error) => {
            if (error.response) {
              this.save = 'Something went wrong.';
            } else {
            // console.log("Please check your internet connection!")
              this.save = 'No internet. Retrying...';
            }
          });
      }, 10000);
    },

    imgDetect(event) {
      if (event.target.nodeName === 'IMG') {
        const zoomModal = new Modal(document.getElementById('zoommodal'), {});
        zoomModal.show();
        document.getElementById('zoomimg').src = event.target.src;
      }
    },
  },

  mounted() {
    document.body.addEventListener('dblclick', this.imgDetect);
    this.assID = this.$route.params.id;
    this.getAss();

    document.addEventListener('visibilitychange', this.switchDetect);

    document.body.addEventListener('mouseleave', this.leaveDetect);
    document.body.addEventListener('keyup', this.detectChange);
  },

  beforeRouteLeave(to, from, next) {
    this.detectChange();
    const answer = window.confirm('Are you sure you want to leave? This may use up one attempt.');
    if (answer) {
      document.removeEventListener('visibilitychange', this.switchDetect);
      document.body.removeEventListener('mouseleave', this.leaveDetect);
      document.body.removeEventListener('dblclick', this.imgDetect);
      document.body.removeEventListener('keyup', this.detectChange);
      clearInterval(this.timer);
      next();
      // const video = document.querySelector('video');
      // video.srcObject.getTracks().forEach(function(track) {
      //   track.stop();
      // });
    } else {
      next(false);
    }
  },
};
</script>

<style scoped>
</style>
