<template>
  <div v-if="loadScreen">
  </div>
  <div id="assconfig" class="container-fluid py-5 col-8 mx-auto" v-else>
    <nav class="navbar bg-white fixed-top flex-md-nowrap p-0 navbar-expand-md">
      <button class = "navbar-toggler" type="button" data-bs-toggle="collapse"
      data-target="#collapsibleNavbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class = "row mx-0 collapse navbar-collapse justify-content-between"
      id="collapsibleNavbar">
        <i class="col-sm-1 text-center text-primary bg-white material-icons md-40">
        assignment</i>
        <div class="col-sm-2">
          <h5 class="my-auto text-secondary"> {{aTitle}} </h5>
        </div>

        <div class=" col-sm-6 pr-0 text-end row mx-0">
            <button class="col-sm-3 text-end btn btn-inline btn-sm"> <i
              class="material-icons align-middle" @click="prevResponse"> navigate_before</i>
            </button>
            <div class="col-sm-6 text-center">
              <p class="my-auto"> <strong class="text-danger"> Grading: </strong> {{name}}</p>
              <small>
                {{submitTime}}
              </small>
            </div>
             <button class="col-sm-3 btn btn-inline btn-sm text-start">
              <i class="material-icons align-middle" @click="nextResponse"> navigate_next </i>
             </button>
        </div>

        <div class="ms-auto col-sm-3 text-end">
          <JsonCSV
            class="btn btn-sm me-1 btn-primary"
            :data="exportGrades"
            :name="`${aTitle}_grades.csv`">
            Export Grades
          </JsonCSV>
          <button class="me-1 btn btn-sm btn-primary" @click="exportPDf">
            Export
          </button>
          <button class="me-1 btn btn-sm btn-danger" @click="closePage"> Close </button>
        </div>
      </div>
    </nav>
    <div class="modal fade" id="exportModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Exporting to PDF...</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="my-4 text-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="assarea">
      <div v-for='pDict,s in sDict' @mouseover="activeS=s" :key="s">
        <div v-for="p,q in pDict" class="p-cards" :key="q"
          @click="editMode('sec'+s+'prob'+q)">
          <grading-template :ref="'sec'+s+'prob'+q" :q-info="p" :q-no="q"
          :s-no="s" :s-len="sDict.length"
          :s-resp='answers[s][q]'>
          </grading-template>
        </div>
        {{updateMath()}}
      </div>
    </div>

    <div id="zoommodal" class="modal fade justify-content-center" role="dialog">
      <div id="zoom-dialog" class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content"
        style="background-color: transparent!important; border: none!important;">
          <div class="modal-header" >
            <button type="button" class="btn-close"
            data-bs-dismiss="modal" aria-label="Close">
            </button>
          </div>
          <div class="modal-body p-0 bg-white">
            <img id="zoomimg" src="">
          </div>
        </div>
      </div>
    </div>

    <div class="points-bar col-md-2 bg-white rounded p-2">
      <p class="text-muted my-auto"> Total Points </p>
      <p class="h5 float-start ms-1" style="color: #bb4b25;">{{recPoints}} / {{totPoints}} </p>
      <button type="button" :class="[statusClass]"
      class="btn float-end btn-sm" @click="saveGrades()">{{cMsg}}</button>
    </div>

    <div class="time-bar col-md-2 bg-white rounded p-2">
      <p class="text-muted my-auto d-inline">
        Integrity Checks
      </p>
      <i class="material-icons text-danger float-end align-middle md-16">report_problem</i>
      <div class="mb-0 text-center"> <small class="text-muted"> Low Severity: </small>
        <strong class="text-danger">{{flags.leave}} </strong> </div>
      <div class="text-center"> <small class="text-muted">High Severity: </small>
        <strong class="text-danger">{{flags.switch}} </strong></div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
import * as dateUtils from '@/utils/dateUtils';
import JsonCSV from 'vue-json-csv';
import { Modal } from 'bootstrap';
import GradingTemplate from '../components/GradeProblem.vue';

export default {
  name: 'GradeAss',
  props: ['userData'],
  components: {
    GradingTemplate,
    JsonCSV,
  },
  computed: {
    recPoints() {
      let tempPoints = 0;
      this.answers.forEach((section) => {
        section.forEach((problem) => {
          if (problem.awarded) {
            tempPoints += parseInt(problem.awarded, 10);
          }
        });
      });
      return tempPoints;
    },
    loadScreen() {
      return this.$store.state.loadScreen;
    },
    flags() {
      if (!this.loadScreen && this.responses[0]) {
        return this.responses[0].flags;
      }
      return 'No Flags';
    },
    submitTime() {
      if (!this.loadScreen && this.responses[0]) {
        const submitted = new Date(this.responses[0].submitted).toLocaleString('en-UK', {});
        return `Submitted at ${submitted}`;
      }
      return 'No Submissions';
    },
    name() {
      if (this.responses[0]) {
        const studentName = `${this.responses[0].guestInfo.givenName}
         ${this.responses[0].guestInfo.lastName} (${this.responses[0].guestEmail})`;
        return studentName;
      }
      return 'N/A';
    },
    assID() {
      return this.$store.state.selectedAss;
    },
    exportGrades() {
      const gradeArray = [];
      this.responses.forEach((response, index) => {
        const gradeDetails = {
          ID: index + 1,
          'Last Name': response.guestInfo.lastName,
          'First Name': response.guestInfo.givenName,
          email: response.guestEmail,
          Score: response.points,
          Percent: `${(parseFloat(response.points) / parseFloat(this.totPoints))
            * 100}%`,
          'Low Severity': response.flags.leave,
          'High Severity': response.flags.switch,
        };
        gradeArray.push(gradeDetails);
      });
      return gradeArray;
    },
  },
  data() {
    return {
      aId: null,
      responses: [],
      aTitle: null,
      aType: null,
      totPoints: 0,
      activeS: 0,
      sDict: [],
      submitted: [],
      answers: [],
      cMsg: 'Confirm',
      statusClass: 'btn-primary',
    };
  },
  methods: {
    editMode(elem) {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          if (key !== elem && this.$refs[key][0].active) {
            this.$refs[key][0].setInactive();
          }
        }
      });
      this.$refs[elem][0].setActive();
    },

    nextResponse() {
      // this.saveGrades();
      const cur = this.responses.shift();
      this.responses.push(cur);
      // this.sDict = this.responses[0][4];
      // [[, , , , this.answers]] = this.responses;
      this.answers = this.responses[0].response;
      setTimeout(() => {
        this.cMsg = 'Confirm';
        this.statusClass = 'btn-primary';
      }, 100);
    },

    prevResponse() {
      // this.saveGrades();
      const last = this.responses.pop();
      this.responses.unshift(last);
      // this.sDict = this.responses[0][4];
      // [[, , , , this.answers]] = this.responses;
      this.answers = this.responses[0].response;
      setTimeout(() => {
        this.cMsg = 'Confirm';
        this.statusClass = 'btn-primary';
      }, 100);
    },

    getResponse() {
      this.$store.commit('setLoadScreen', { status: true });
      axios.post('/api/assessment/getallresponses', {
        aId: this.assID,
      })
        .then((response) => {
          this.responses = JSON.parse(response.data.responses);
          this.answers = this.responses[0].response;
          const tempAss = JSON.parse(response.data.assignment);
          this.aTitle = tempAss.title;
          this.aType = tempAss.aType.type;
          this.totPoints = tempAss.totPoints;
          this.sDict = JSON.parse(tempAss.problems);
          this.$store.commit('setLoadScreen', { status: false });
        })
        .catch((error) => {
          console.log(error);
          this.$store.commit('setLoadScreen', { status: false });
        });
    },

    updateMath() {
      setTimeout(() => {
        window.MathJax.typesetPromise();
      }, 100);
    },

    closePage() {
      this.$router.go(-1);
    },

    saveGrades() {
      axios.post('/api/assessment/updategrades', {
        response: this.answers,
        respId: this.responses[0]._id.$oid, // eslint-disable-line no-underscore-dangle
        points: this.recPoints,
      })
        .then(() => {
          this.cMsg = 'Confirmed!';
          this.statusClass = 'btn-success';
        });
    },

    imgDetect(event) {
      if (event.target.nodeName === 'IMG') {
        const zoomModal = new Modal(document.getElementById('zoommodal'), {});
        zoomModal.show();
        document.getElementById('zoomimg').src = event.target.src;
      }
    },
    exportPDf() {
      Object.keys(this.$refs).forEach((key) => {
        if (this.$refs[key][0]) {
          if (this.$refs[key][0].active) {
            this.$refs[key][0].setInactive();
          }
        }
      });
      setTimeout(() => {
        const a = window.open('', '', 'height=700, width=900');
        const source = document.getElementById('assarea').innerHTML;
        let stylesHtml = '';
        document.querySelectorAll('link[rel="stylesheet"], style').forEach((node) => {
          stylesHtml += node.outerHTML;
        });
        /* eslint-disable no-useless-escape */
        a.document.write(`<html>
          <title>${this.aTitle} - ${this.name}</title>
          ${stylesHtml}
          <script src="https://polyfill.io/v3/polyfill.min.js?features=es6"><\/script>
          <script id="MathJax-script"
          async src="https://cdn.jsdelivr.net/npm/mathjax@3/es5/tex-mml-chtml.js"><\/script>
          </head><body>`);
        a.document.write(`
          <div class="f-response q-field col-8 mx-auto mb-2 px-4 py-1">
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Title: </p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.aTitle}</p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Student: </p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.name}</p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Submitted:</p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${dateUtils.default.prettyDate(this.responses[0].submitted)}</p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Score:</p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.recPoints}/${this.totPoints}</p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">Low Severity:</p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.flags.leave}</p>
              </div>
            </div>
            <div class="row mx-0">
              <div class="col">
                <p class="ms-5 print-title">High Severity:</p>
              </div>
              <div class="col">
                <p class="ms-5 print-field">${this.flags.switch}</p>
              </div>
            </div>
          </div>`);
        a.document.write(source);
        a.document.write('</body></html>');
        a.document.close();
        setTimeout(() => {
          a.print();
          a.close();
        }, 1000);
        /* eslint-enable no-useless-escape */
      }, 100);
    },
  },

  mounted() {
    document.body.addEventListener('dblclick', this.imgDetect);
    this.aId = this.$route.params.aid;
    this.getResponse();
  },
};
</script>
