import Vue from 'vue';
import VueRouter from 'vue-router';
import Register from '../views/Register.vue';
import Login from '../views/Login.vue';
import UserMgmt from '../views/UserMgmt.vue';
import Account from '../views/Account.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import UpdatePassword from '../views/UpdatePassword.vue';
import EmailUnverified from '../views/EmailUnverified.vue';
// import PasswordReset2 from '../views/PasswordReset2.vue';
import CreateAss from '../views/CreateAss.vue';
import GradeAss from '../views/GradeAss.vue';
import ReviewAss from '../views/ReviewAss.vue';
import Dashboard from '../views/Dashboard.vue';
import ProctorView from '../views/ProctorView.vue';
import TakeScreening from '../components/TakeScreening.vue';
import TakeLanding from '../views/TakeLanding.vue';
import TakeAss from '../components/TakeAss.vue';
import store from '../store/index';
// import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/create',
    name: 'CreateAss',
    component: CreateAss,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/grade',
    name: 'GradeAss',
    component: GradeAss,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/monitor',
    name: 'ProctorView',
    component: ProctorView,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/take/:id/',
    name: 'TakeLanding',
    component: TakeLanding,
    children: [
      {
        path: '',
        name: 'TakeScreening',
        component: TakeScreening,
      },
      {
        path: 'room',
        name: 'TakeAss',
        component: TakeAss,
      },
      {
        path: 'review',
        name: 'ReviewAss',
        component: ReviewAss,
      },
    ],
  },
  {
    path: '/account',
    name: 'Account',
    component: Account,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/forgotpassword',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/updatepassword',
    name: 'UpdatePassword',
    component: UpdatePassword,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/emailunverified',
    name: 'EmailUnverified',
    component: EmailUnverified,
  },
  {
    path: '/usermgmt',
    name: 'UserMgmt',
    component: UserMgmt,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
// To add token and email verfication

router.beforeEach((to, from, next) => {
  store.commit('setError', { status: false });
  const requiresAuth = to.matched.some((x) => x.meta.requiresAuth);
  if (requiresAuth) {
    if (!store.state.loggedIn) {
      next({ path: '/login', query: { redirect: to.fullPath } });
    } else if (!store.state.emailVerified) {
      next({ path: '/emailunverified' });
    } else {
      next();
    }
  } else {
    next();
  }
});
